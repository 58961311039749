import _toConsumableArray from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/toConsumableArray";
import _defineProperty2 from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty2(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _Number$MAX_SAFE_INTEGER from "@babel/runtime-corejs3/core-js-stable/number/max-safe-integer";
import _Number$MIN_SAFE_INTEGER from "@babel/runtime-corejs3/core-js-stable/number/min-safe-integer";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _parseFloat from "@babel/runtime-corejs3/core-js-stable/parse-float";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";

function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();

  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
        result;

    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;

      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }

    return _possibleConstructorReturn(this, result);
  };
}

function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;

  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { intlMessageShape, DATA_TYPE_STRING, DATA_TYPE_NUMBER } from '@jutro/prop-types';
import { getMessageProp } from '@jutro/platform';
import { isEmptyValue, isNumber } from '@jutro/data';
import isEqual from "lodash/isEqual";
import cx from 'classnames';
import { FieldComponent } from '../FieldComponent/FieldComponent';
import { NumberValue } from '../../values/NumberValue';
import { NumberInput } from './NumberInput';
import inputFieldStyles from "../InputField/InputField.module.css";
import { FieldIcon } from '../FieldComponent/FieldIcon';
import { messages } from './InputNumberField.messages';
var defaultDecimalPlaces = 2;
var numberDataTypeShape = PropTypes.oneOf([DATA_TYPE_STRING, DATA_TYPE_NUMBER]);

var inputNumberFieldPropTypes = _objectSpread({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dataType: numberDataTypeShape,
  autoComplete: PropTypes.bool,
  minValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  decimalPlaces: PropTypes.number,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}, FieldIcon.propTypes, {
  messageProps: PropTypes.shape({
    validationMinValue: intlMessageShape,
    validationMaxValue: intlMessageShape,
    validationInvalidNumber: intlMessageShape
  })
});

export var InputNumberField = function (_FieldComponent) {
  _inherits(InputNumberField, _FieldComponent);

  var _super = _createSuper(InputNumberField);

  function InputNumberField() {
    var _this;

    _classCallCheck(this, InputNumberField);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _defineProperty(_assertThisInitialized(_this), "handleChange", function (value) {
      var dataType = _this.props.dataType;

      if (isEmptyValue(value)) {
        _this.notifyChange('');
      }

      if (isNumber(value)) {
        if (dataType === DATA_TYPE_NUMBER) {
          _this.notifyChange(_parseFloat(value));
        } else {
          _this.notifyChange(value);
        }
      }
    });

    return _this;
  }

  _createClass(InputNumberField, [{
    key: "render",
    value: function render() {
      return _get(_getPrototypeOf(InputNumberField.prototype), "render", this).call(this);
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps) {
      var prevValue = prevProps.value,
          prevMessages = prevProps.validationMessages;
      var _this$props = this.props,
          value = _this$props.value,
          validationMessages = _this$props.validationMessages;

      if (!isEqual(prevValue, value) || !isEqual(prevMessages, validationMessages)) {
        this.validate(value);
      }
    }
  }, {
    key: "getValidationMessages",
    value: function getValidationMessages() {
      var _context;

      var _this$props2 = this.props,
          value = _this$props2.value,
          maxValue = _this$props2.maxValue,
          minValue = _this$props2.minValue,
          messageProps = _this$props2.messageProps;
      var superValidationMessages = _get(_getPrototypeOf(InputNumberField.prototype), "getValidationMessages", this).call(this) || [];

      var _map = _mapInstanceProperty(_context = ['validationMinValue', 'validationMaxValue']).call(_context, function (propName) {
        return getMessageProp(propName, messageProps, messages);
      }),
          _map2 = _slicedToArray(_map, 2),
          validationMinValue = _map2[0],
          validationMaxValue = _map2[1];

      var validationMessages = _toConsumableArray(superValidationMessages);

      if (value < (minValue !== null && minValue !== void 0 ? minValue : _Number$MIN_SAFE_INTEGER)) {
        validationMessages.push(this.translator(validationMinValue, {
          value: minValue
        }));
      }

      if (value > (maxValue !== null && maxValue !== void 0 ? maxValue : _Number$MAX_SAFE_INTEGER)) {
        validationMessages.push(this.translator(validationMaxValue, {
          value: maxValue
        }));
      }

      return validationMessages;
    }
  }, {
    key: "renderControl",
    value: function renderControl(breakpointProps, options) {
      var styles = inputFieldStyles;
      var id = breakpointProps.id,
          value = breakpointProps.value,
          maxLength = breakpointProps.maxLength,
          decimalPlaces = breakpointProps.decimalPlaces,
          disabled = breakpointProps.disabled,
          controlClassName = breakpointProps.controlClassName,
          required = breakpointProps.required,
          autoComplete = breakpointProps.autoComplete,
          minValue = breakpointProps.minValue,
          maxValue = breakpointProps.maxValue,
          step = breakpointProps.step;
      var inputStyle = cx(styles.input, {
        disabled: disabled,
        invalid: !options.isValid
      }, controlClassName);
      return React.createElement(FieldIcon, breakpointProps, React.createElement(NumberInput, _extends({
        id: id,
        className: inputStyle,
        maxLength: maxLength,
        value: value,
        onChange: this.handleChange,
        disabled: disabled,
        required: required,
        min: minValue,
        max: maxValue,
        step: step,
        decimalPlaces: decimalPlaces,
        autoComplete: autoComplete ? 'on' : 'off'
      }, this.generateDataPathProperty(), this.generateAccessibilityProperties(), this.getInputFocusHandlers())));
    }
  }, {
    key: "renderControlReadOnly",
    value: function renderControlReadOnly(breakpointProps) {
      var id = breakpointProps.id,
          value = breakpointProps.value,
          decimalPlaces = breakpointProps.decimalPlaces;
      return React.createElement(NumberValue, {
        id: id,
        value: value,
        showFractions: true,
        maximumFractionDigits: decimalPlaces
      });
    }
  }]);

  return InputNumberField;
}(FieldComponent);

_defineProperty(InputNumberField, "propTypes", _objectSpread({}, FieldComponent.propTypes, {}, inputNumberFieldPropTypes));

_defineProperty(InputNumberField, "defaultProps", _objectSpread({}, FieldComponent.defaultProps, {
  autoComplete: true,
  dataType: DATA_TYPE_NUMBER,
  decimalPlaces: defaultDecimalPlaces,
  step: 1
}));

_defineProperty(InputNumberField, "contextType", FieldComponent.contextType);

InputNumberField.__docgenInfo = {
  componentName: "InputNumberField",
  packageName: "@jutro/components",
  description: "Renders an input element for number fields.",
  displayName: "InputNumberField",
  methods: [{
    name: "getValidationMessages",
    docblock: "Get the validation messages to display.\nIf field is 'required' and no validation message is provided, it is added\nIf field is 'number' and have max and min value and no validation message is provided, it is added\n\n@returns {Array<any>} validation messages",
    modifiers: [],
    params: [],
    returns: {
      description: "validation messages",
      type: {
        name: "Array",
        elements: [{
          name: "any"
        }]
      }
    },
    description: "Get the validation messages to display.\nIf field is 'required' and no validation message is provided, it is added\nIf field is 'number' and have max and min value and no validation message is provided, it is added"
  }, {
    name: "handleChange",
    docblock: "Default change handler for `input` element. It will be used unless this method or `renderControl()`\nis overridden.\n\n@param {object} value - new value",
    modifiers: [],
    params: [{
      name: "value",
      description: "new value",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: null,
    description: "Default change handler for `input` element. It will be used unless this method or `renderControl()`\nis overridden."
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@param {object} options - rendering options (like 'isInvalid', 'isValid', etc)\n\n@returns {React.ReactNode} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }, {
      name: "options",
      description: "rendering options (like 'isInvalid', 'isValid', etc)",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactNode"
      }
    },
    description: "Render control for this component."
  }, {
    name: "renderControlReadOnly",
    docblock: "Render readonly control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render readonly control for this component."
  }],
  actualName: "InputNumberField",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }]
      },
      required: false,
      description: "Number passed as value"
    },
    dataType: {
      type: {
        name: "custom",
        raw: "numberDataTypeShape"
      },
      required: false,
      description: "Type of returned value in onValeChange callback",
      defaultValue: {
        value: "'number'",
        computed: false
      }
    },
    autoComplete: {
      type: {
        name: "bool"
      },
      required: false,
      description: "HTML5 native autoComplete support",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    minValue: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }]
      },
      required: false,
      description: "Minimum value for the number"
    },
    maxValue: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }]
      },
      required: false,
      description: "Maximum value for the number"
    },
    decimalPlaces: {
      type: {
        name: "number"
      },
      required: false,
      description: "The number of decimal places to display in the value",
      defaultValue: {
        value: "2",
        computed: false
      }
    },
    step: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }]
      },
      required: false,
      description: "Step for increment/decrement like `0.05`",
      defaultValue: {
        value: "1",
        computed: false
      }
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          validationMinValue: {
            name: "union",
            description: "Validation message for min value",
            required: false,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          },
          validationMaxValue: {
            name: "union",
            description: "Validation message for max value",
            required: false,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          },
          validationInvalidNumber: {
            name: "union",
            description: "Validation message for not a number",
            required: false,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          }
        }
      },
      required: false,
      description: "Message props(error message/aria-label)"
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};