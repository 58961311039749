import _defineProperty from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import React, { useMemo, useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { intlMessageShape } from '@jutro/prop-types';
import { IconButton } from '../IconButton/IconButton';
import styles from "./Tag.module.css";
import { messages } from './Tag.messages';
var tagPropTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  renderIcon: PropTypes.func,
  label: intlMessageShape,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  iconWrapperClassName: PropTypes.string,
  iconClassName: PropTypes.string
};
export var Tag = function Tag(_ref) {
  var onClick = _ref.onClick,
      renderIcon = _ref.renderIcon,
      label = _ref.label,
      disabled = _ref.disabled,
      icon = _ref.icon,
      className = _ref.className,
      labelClassName = _ref.labelClassName,
      iconWrapperClassName = _ref.iconWrapperClassName,
      iconClassName = _ref.iconClassName,
      id = _ref.id;
  var translator = useContext(TranslatorContext);
  var classes = cx(styles.tag, _defineProperty({}, styles.disabled, disabled), className);
  var labelClasses = cx(styles.label, labelClassName);
  var iconWrapperClasses = cx(styles.deleteButtonWrapper, _defineProperty({}, styles.empty, !onClick), iconWrapperClassName);
  var iconClasses = cx(styles.deleteButton, iconClassName);
  var deleteIcon = useMemo(function () {
    if (!onClick) {
      return undefined;
    }

    var iconButton = React.createElement(IconButton, {
      disabled: disabled,
      className: iconClasses,
      icon: icon,
      onKeyDown: onClick,
      onMouseDown: onClick,
      ariaLabel: messages.closeTagLabel
    });
    return renderIcon ? renderIcon(iconButton) : iconButton;
  }, [disabled, icon, iconClasses, onClick, renderIcon]);
  return React.createElement("span", {
    id: id,
    className: classes
  }, React.createElement("span", {
    className: labelClasses
  }, translator(label)), React.createElement("span", {
    className: iconWrapperClasses
  }, deleteIcon));
};
Tag.propTypes = tagPropTypes;
Tag.defaultProps = {
  icon: 'mi-close'
};
Tag.__docgenInfo = {
  componentName: "Tag",
  packageName: "@jutro/components",
  description: "Tag",
  displayName: "Tag",
  methods: [],
  actualName: "Tag",
  metadataType: "element",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: false,
      description: "The id for this Tag"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback for icon button click, If not provided button isn't rendered"
    },
    icon: {
      type: {
        name: "string"
      },
      required: false,
      description: "Icon name to render inside icon button",
      defaultValue: {
        value: "'mi-close'",
        computed: false
      }
    },
    renderIcon: {
      type: {
        name: "func"
      },
      required: false,
      description: "Render prop for adding custom wrapper for icon button"
    },
    label: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Displayed tag label"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Renders tag as disabled"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Custom class name for tag"
    },
    labelClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Custom class name for label"
    },
    iconWrapperClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Custom class name for icon wrapper"
    },
    iconClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Custom class name for icon"
    }
  }
};