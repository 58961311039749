import _defineProperty from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _everyInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/every";
import { SELECT, TOGGLE, SET_ALL, CLEAR_ALL } from './types';
import { toggleValue } from './helper';
export function reducer(state, _ref) {
  var type = _ref.type,
      payload = _ref.payload;

  switch (type) {
    case SELECT:
      if (areEqual([payload], state.rows)) {
        return _objectSpread({}, state, {
          all: false
        });
      }

      return _objectSpread({}, state, {
        all: false,
        rows: [payload]
      });

    case TOGGLE:
      {
        var rows = state.rows;
        var newRows = toggleValue(rows, payload);
        return _objectSpread({}, state, {
          all: false,
          rows: newRows
        });
      }

    case SET_ALL:
      if (areEqual(payload, state.rows)) {
        return _objectSpread({}, state, {
          all: true
        });
      }

      return _objectSpread({}, state, {
        all: true,
        rows: payload
      });

    case CLEAR_ALL:
      if (!state.rows.length) {
        return _objectSpread({}, state, {
          all: false
        });
      }

      return _objectSpread({}, state, {
        all: false,
        rows: []
      });

    default:
      return state;
  }
}

function areEqual(array1, array2) {
  if (array1.length !== array2.length) {
    return false;
  }

  return _everyInstanceProperty(array1).call(array1, function (item) {
    return _includesInstanceProperty(array2).call(array2, item);
  });
}