import _defineProperty from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { TranslatorContext, useSafeTranslatedUrls } from '@jutro/locale';
import { intlMessageShape, intlToShape } from '@jutro/prop-types';
import styles from "./Breadcrumb.module.css";
import { messages } from './Breadcrumb.messages';
import { Link } from '../Link/Link';
var breadcrumbPropTypes = {
  className: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape({
    text: intlMessageShape.isRequired,
    href: intlMessageShape,
    to: intlToShape,
    className: PropTypes.string
  })),
  theme: PropTypes.object,
  renderLinkTrigger: PropTypes.func,
  lastItemClickable: PropTypes.bool,
  backLinkWithoutText: PropTypes.bool
};
export var Breadcrumb = function Breadcrumb(props) {
  var className = props.className,
      links = props.links,
      renderLinkTrigger = props.renderLinkTrigger,
      lastItemClickable = props.lastItemClickable,
      backLinkWithoutText = props.backLinkWithoutText;
  var translator = useContext(TranslatorContext);
  var urlTranslatorAndSanitizer = useSafeTranslatedUrls();

  if (!(links !== null && links !== void 0 && links.length)) {
    return null;
  }

  var breadcrumbClasses = cx(styles.breadcrumb, className);
  var divider = React.createElement("span", {
    "aria-hidden": "true",
    className: styles.divider
  }, "/");

  var defaultRenderLink = function defaultRenderLink(_ref) {
    var text = _ref.text,
        linkProps = _objectWithoutProperties(_ref, ["text"]);

    return React.createElement(Link, linkProps, text);
  };

  var renderLinkItem = renderLinkTrigger || defaultRenderLink;

  var renderReadOnlyItem = function renderReadOnlyItem(_ref2) {
    var text = _ref2.text;
    return React.createElement("span", {
      className: styles.currentLink
    }, text);
  };

  var breadcrumbs = _mapInstanceProperty(links).call(links, function (_ref3, index, array) {
    var linkClassName = _ref3.className,
        href = _ref3.href,
        to = _ref3.to,
        text = _ref3.text,
        linkProps = _objectWithoutProperties(_ref3, ["className", "href", "to", "text"]);

    var linkClasses = cx(styles.breadcrumbLink, linkClassName);
    var translatedText = translator(text);
    var isLastItem = array.length - 1 === index;
    var isReadOnly = isLastItem && !lastItemClickable;
    var ariaCurrent = isLastItem && lastItemClickable ? {
      'aria-current': 'page'
    } : {};
    return React.createElement(React.Fragment, {
      key: "breadcrumb_link_".concat(index)
    }, index > 0 && divider, isReadOnly ? renderReadOnlyItem({
      text: translatedText
    }) : renderLinkItem(_objectSpread({
      className: linkClasses,
      text: translatedText,
      href: href && urlTranslatorAndSanitizer(href),
      to: to && urlTranslatorAndSanitizer(to)
    }, ariaCurrent, {}, linkProps)));
  });

  var backLink = function backLink() {
    var lastItem = links[links.length - 1];
    var isLastItemNotClickable = !lastItem.href && !lastItem.to || lastItemClickable === false;
    var item = isLastItemNotClickable ? links[0] : lastItem;
    var linkClassName = item.linkClassName,
        href = item.href,
        to = item.to;
    var linkClasses = cx(styles.breadcrumbLink, linkClassName);
    var label = translator(messages.breadcrumbBackButtonLabel);
    var translatedText = backLinkWithoutText ? '' : label;
    return React.createElement(React.Fragment, null, renderLinkItem(_objectSpread({
      icon: 'mi-keyboard-back',
      className: linkClasses,
      text: translatedText,
      href: href && urlTranslatorAndSanitizer(href),
      to: to && urlTranslatorAndSanitizer(to),
      'aria-label': label
    }, item.linkProps)));
  };

  return React.createElement("div", {
    className: breadcrumbClasses,
    "aria-label": translator(messages.breadcrumbLabel),
    role: "navigation"
  }, links.length > 2 && breadcrumbs, links.length <= 2 && backLink());
};
Breadcrumb.propTypes = breadcrumbPropTypes;
Breadcrumb.__docgenInfo = {
  componentName: "Breadcrumb",
  packageName: "@jutro/components",
  description: "Displays a \"breadcrumb\" of links to previous pages with anchor tags or Link components",
  displayName: "Breadcrumb",
  methods: [],
  actualName: "Breadcrumb",
  metadataType: "action",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class to wrap the component."
    },
    links: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            text: {
              name: "union",
              required: true,
              value: [{
                name: "string"
              }, {
                name: "shape",
                value: {
                  id: {
                    name: "string",
                    required: false
                  },
                  defaultMessage: {
                    name: "string",
                    required: false
                  },
                  args: {
                    name: "shape",
                    value: {},
                    required: false
                  }
                }
              }]
            },
            href: {
              name: "union",
              required: false,
              value: [{
                name: "string"
              }, {
                name: "shape",
                value: {
                  id: {
                    name: "string",
                    required: false
                  },
                  defaultMessage: {
                    name: "string",
                    required: false
                  },
                  args: {
                    name: "shape",
                    value: {},
                    required: false
                  }
                }
              }]
            },
            to: {
              name: "custom",
              raw: "intlToShape",
              required: false
            },
            className: {
              name: "string",
              required: false
            }
          }
        }
      },
      required: false,
      description: "Takes an array of objects, each item having props for the used Link type component."
    },
    theme: {
      type: {
        name: "object"
      },
      required: false,
      description: "Theme config to apply."
    },
    renderLinkTrigger: {
      type: {
        name: "func"
      },
      required: false,
      description: "RenderProp function to generate the component that makes up the breadcrumb."
    },
    lastItemClickable: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Determines if the last item is a link."
    },
    backLinkWithoutText: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Determines if the backLink include \"Back\" text or just \"arrow\" icon."
    }
  }
};