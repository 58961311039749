import _defineProperty2 from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty2(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";

function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();

  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
        result;

    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;

      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }

    return _possibleConstructorReturn(this, result);
  };
}

function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;

  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { mergeStyles } from '@jutro/platform';
import { FieldComponent } from '../FieldComponent/FieldComponent';
import inputStyles from "../InputField/InputField.module.css";
import yearStyles from "./YearField.module.css";
export var YearField = function (_FieldComponent) {
  _inherits(YearField, _FieldComponent);

  var _super = _createSuper(YearField);

  function YearField() {
    _classCallCheck(this, YearField);

    return _super.apply(this, arguments);
  }

  _createClass(YearField, [{
    key: "render",
    value: function render() {
      return _get(_getPrototypeOf(YearField.prototype), "render", this).call(this);
    }
  }, {
    key: "renderControl",
    value: function renderControl(breakpointProps) {
      var id = breakpointProps.id,
          value = breakpointProps.value,
          disabled = breakpointProps.disabled,
          required = breakpointProps.required,
          controlClassName = breakpointProps.controlClassName;
      var focusHandlers = this.getInputFocusHandlers();
      var styles = mergeStyles(inputStyles, yearStyles);
      var classes = cx(styles.input, styles.yearField, controlClassName);
      var control = React.createElement("input", _extends({
        id: id,
        type: "tel",
        className: classes,
        maxLength: "4",
        value: value || '',
        onChange: this.handleChange
      }, focusHandlers, {
        disabled: disabled,
        required: required
      }, this.generateDataPathProperty(), this.generateAccessibilityProperties()));
      return control;
    }
  }]);

  return YearField;
}(FieldComponent);

_defineProperty(YearField, "propTypes", _objectSpread({}, FieldComponent.propTypes, {
  value: PropTypes.string
}));

YearField.__docgenInfo = {
  componentName: "YearField",
  packageName: "@jutro/components",
  description: "IN PROGRESS: Renders a field where the user can enter 4-digit year.",
  displayName: "YearField",
  methods: [{
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  actualName: "YearField",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "string"
      },
      required: false,
      description: "Value to display in control"
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};