import _ from 'lodash';
import React from 'react';
import cx from 'classnames';
import { ServiceManager } from '@jutro/services';
import { FieldComponent, FieldIcon } from '@jutro/components';
import { DATA_TYPE_OBJECT } from '@jutro/prop-types';
import format from 'date-fns/format';
import enUS from 'date-fns/locale/en-US';
import fr from 'date-fns/locale/fr';
import nl from 'date-fns/locale/nl';
import DatePickerInternal from './PickerInternal/DatePickerInternal';
import ReadOnlyDateValue from './ReadOnlyDateValue';
import { datePickerStyles, inputFieldStyles } from './styles';
import CustomTimePicker from '../CustomTimePicker/CustomTimePicker';

const getInputSizeClassnames = (inputStyles, size) => cx({
    [inputStyles.inputSmall]: size === 'small',
    [inputStyles.inputLarge]: size === 'large'
});

class DatePickerComponent extends FieldComponent {
    state = {
        isValid: true,
        isEmpty: false
    }

    createFormat = (props) => {
        if (props.showTime) {
            return `${props.countryFormat}${props.separator}${props.timeFormat}`;
        }

        return `${props.countryFormat}`;
    }

    constructor(props) {
        super(props);
        this.localeService = ServiceManager.getService('locale');
        this.placeholder = props.placeholder;
        this.showTime = props.showTime;
        this.countryFormat = props.countryFormat;
        this.separator = props.separator;
        this.timeFormat = props.timeFormat;
        this.dateTimeFormat = this.createFormat(props);
    }

    formatDate = (date, formatString, dateLocale) => {
        return format(date, formatString, { locale: dateLocale });
    };

    getFnsLocale = (locale) => {
        switch (locale) {
            case 'fr-FR':
                return fr;
            case 'nl-NL':
                return nl;
            default:
                return enUS;
        }
    };

    getStoredFnsLocale = () => {
        const locale = this.localeService.getStoredLocale();
        return this.getFnsLocale(locale);
    }

    handleChange = (value, isValid, isEmpty) => {
        this.setState({ isValid, isEmpty });
        this.notifyChange(value);
    }

    passedOrCreatedPlaceHolder = () => {
        if (this.placeholder) return this.placeholder;

        return this.countryFormat;
    };

    renderControlReadOnly = (breakpointProps) => {
        const { value } = breakpointProps;
        if (_.isNil(value)) {
            return '-';
        }

        const {
            year,
            month,
            day,
            hour = 0,
            minute = 0
        } = value;
        const dateValueObject = new Date(year, month, day, hour, minute);
        const formattedValue = this.formatDate(dateValueObject,
            this.dateTimeFormat, this.getStoredFnsLocale());

        return (
            <ReadOnlyDateValue {...breakpointProps} value={formattedValue} />
        );
    }

    renderControl = (breakpointProps) => {
        const combinedStyles = { ...datePickerStyles, ...inputFieldStyles };
        const {
            id,
            disabled,
            controlClassName,
            countryFormat,
            required,
            minDate,
            maxDate,
            size,
            autoComplete,
            dataType,
            showTime,
            todayButtonText,
            popperPlacement,
            value,
            intl,
            onBlur,
            timeFormat,
            separator,
            timeLabel,
            showTimeStampQuestion,
            timeStampLabel,
            timeStampVisibleValue,
            setTimeStampVisibleValue,
            showErrors
        } = breakpointProps;

        const inputClasses = cx({
            disabled: disabled,
            invalid: false
        }, combinedStyles.input,
        combinedStyles.datePicker,
        controlClassName,
        getInputSizeClassnames(combinedStyles, size));

        return (
            <div>
                <div className={combinedStyles.datePicker} aria-expanded="true" aria-haspopup="true">
                    <FieldIcon
                        {...breakpointProps}
                    >
                        <DatePickerInternal
                            id={id}
                            disabled={disabled}
                            className={inputClasses}
                            countryFormat={countryFormat}
                            required={required}
                            placeholder={this.passedOrCreatedPlaceHolder()}
                            minDate={minDate}
                            maxDate={maxDate}
                            dataType={dataType}
                            autoComplete={autoComplete}
                            showTime={showTime}
                            todayButtonText={todayButtonText}
                            popperPlacement={popperPlacement}
                            value={value}
                            intl={intl}
                            onValueChange={this.handleChange}
                            onBlur={onBlur}
                            separator={separator}
                            formatDate={this.formatDate}
                            getFnsLocale={this.getFnsLocale}
                            showErrors={showErrors}
                        />
                    </FieldIcon>
                </div>
                { showTime && (
                    <div>
                        <CustomTimePicker
                            value={value}
                            onValueChange={this.handleChange}
                            label={timeLabel}
                            showTimeStampQuestion={showTimeStampQuestion}
                            timeStampLabel={timeStampLabel}
                            timeStampVisibleValue={timeStampVisibleValue}
                            setTimeStampVisibleValue={setTimeStampVisibleValue}
                        />
                    </div>
                )}
            </div>
        );
    }
}

DatePickerComponent.defaultProps = {
    ...FieldComponent.defaultProps,
    dataType: DATA_TYPE_OBJECT,
    icon: 'mi-calendar-today',
    iconPosition: 'right',
    size: 'medium',
    format: 'long',
    minDate: new Date(1900, 0),
    maxDate: new Date(2100, 0),
    autoComplete: false,
    showTime: false,
    showTimeQuestion: false,
    countryFormat: 'dd/MM/yyyy',
    placeholder: 'DD/MM/YYYY',
    timeFormat: 'HH:mm',
    separator: ', ',
    required: false
};

export default DatePickerComponent;
