import _defineProperty from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _sliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/slice";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import { useMemo } from 'react';
import identity from "lodash/identity";
import { useControlledReducer, bindActionCreators } from '../helper';
import { reducer } from './reducer';
import * as actions from './actions';
export function useSelection(initialState, selectedRows, selectionType, onSelectionChange) {
  var updateState = selectedRows && function (oldState) {
    return _objectSpread({}, oldState, {
      rows: selectedRows
    });
  };

  var _useControlledReducer = useControlledReducer({
    reducer: reducer,
    initialState: initialState,
    controlledState: withNormalizedRows(updateState, selectionType),
    onStateChange: onStateChange,
    updateInControlledMode: !updateState
  }),
      _useControlledReducer2 = _slicedToArray(_useControlledReducer, 2),
      state = _useControlledReducer2[0],
      dispatch = _useControlledReducer2[1];

  var boundActions = useMemo(function () {
    return bindActionCreators(dispatch, actions);
  }, []);
  return [state, boundActions];

  function onStateChange(_ref) {
    var rows = _ref.rows;

    if (onSelectionChange && rows !== state.rows) {
      onSelectionChange(rows);
    }
  }
}

function withNormalizedRows() {
  var updateState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : identity;
  var selectionType = arguments.length > 1 ? arguments[1] : undefined;
  return function (state) {
    var _updateState = updateState(state),
        rows = _updateState.rows,
        rest = _objectWithoutProperties(_updateState, ["rows"]);

    return _objectSpread({}, rest, {
      rows: normalizeSelectedRows(rows, selectionType)
    });
  };
}

function normalizeSelectedRows(selectedRows, selectionType) {
  if (selectionType === 'none') {
    return [];
  }

  return selectionType === 'multi' ? selectedRows : _sliceInstanceProperty(selectedRows).call(selectedRows, 0, 1);
}