import _defineProperty from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { tooltipBaseProps } from '@jutro/prop-types';
import { useSafeTranslatedUrls } from '@jutro/locale';

var propTypes = _objectSpread({}, tooltipBaseProps, {
  className: PropTypes.string,
  titleClass: PropTypes.string,
  textClass: PropTypes.string,
  linkClass: PropTypes.string,
  translator: PropTypes.func
});

export var TooltipContent = function TooltipContent(_ref) {
  var text = _ref.text,
      title = _ref.title,
      link = _ref.link,
      href = _ref.href,
      className = _ref.className,
      titleClass = _ref.titleClass,
      textClass = _ref.textClass,
      linkClass = _ref.linkClass,
      translator = _ref.translator;
  var urlTranslatorAndSanitizer = useSafeTranslatedUrls();
  var safeHref = urlTranslatorAndSanitizer(href);
  return React.createElement("div", {
    className: className,
    role: "alert"
  }, title && React.createElement("div", {
    className: titleClass
  }, React.createElement("strong", null, translator(title))), text && React.createElement("div", {
    className: textClass
  }, translator(text)), link && safeHref && React.createElement("a", {
    className: linkClass,
    href: safeHref,
    target: "_blank",
    rel: "noopener noreferrer"
  }, translator(link)));
};
TooltipContent.propTypes = propTypes;
TooltipContent.__docgenInfo = {
  componentName: "TooltipContent",
  packageName: "@jutro/components",
  description: "Content component that render inside tooltip",
  displayName: "TooltipContent",
  methods: [],
  actualName: "TooltipContent",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Class to customize the component"
    },
    titleClass: {
      type: {
        name: "string"
      },
      required: false,
      description: "Class to customize title"
    },
    textClass: {
      type: {
        name: "string"
      },
      required: false,
      description: "Class to customize text"
    },
    linkClass: {
      type: {
        name: "string"
      },
      required: false,
      description: "Class to customize link"
    },
    translator: {
      type: {
        name: "func"
      },
      required: false,
      description: "Function to translate the content"
    }
  },
  composes: ["@jutro/prop-types"]
};