import _defineProperty from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import units from 'units-css';

var extendPhone = function extendPhone(props) {
  return _objectSpread({}, props, {}, props.tablet, {}, props.phoneWide, {}, props.phone);
};

var extendPhoneWide = function extendPhoneWide(props) {
  return _objectSpread({}, props, {}, props.tablet, {}, props.phoneWide);
};

var extendTablet = function extendTablet(props) {
  return _objectSpread({}, props, {}, props.tablet);
};

var identity = function identity(x) {
  return x;
};

export var breakpointExtensions = {
  phone: extendPhone,
  phoneWide: extendPhoneWide,
  tablet: extendTablet,
  desktop: identity
};
export var extendWithBreakpointValues = function extendWithBreakpointValues(props, breakpoint) {
  return (breakpointExtensions[breakpoint] || identity)(props);
};

var getDeviceDefinitionElement = function getDeviceDefinitionElement(rootElement) {
  var _document, _root$querySelector;

  var root = rootElement || ((_document = document) === null || _document === void 0 ? void 0 : _document.body);
  return (_root$querySelector = root.querySelector('.themeRoot')) !== null && _root$querySelector !== void 0 ? _root$querySelector : root;
};

export var getBreakpointPxValue = function getBreakpointPxValue(device, rootElement) {
  var element = getDeviceDefinitionElement(rootElement);
  var breakpointValue = typeof getComputedStyle === 'function' && getComputedStyle(element).getPropertyValue("--GW-BREAKPOINT-".concat(device.toUpperCase()));
  return breakpointValue && units.convert('px', breakpointValue, element);
};
export var getBreakpoints = function getBreakpoints(rootElement) {
  return {
    phone: getBreakpointPxValue('phone', rootElement) || 580,
    phoneWide: getBreakpointPxValue('phoneWide', rootElement) || 768,
    tablet: getBreakpointPxValue('tablet', rootElement) || 1024
  };
};