/* eslint-disable max-len */
import _ from 'lodash';
import { useCallback, useContext } from 'react';
import { ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { ModalNextProvider } from '@jutro/components';
import { FNOLService } from 'nn-capability-fnol';
import { TranslatorContext } from '@jutro/locale';
import { redirectToAuthContactCallCenter } from '../utils/RedirectionUtil';
import nnCustomMessages from '../nnCustomMessages';
import FNOLContext from '../components/FNOLWizardContext/FNOLWizardContext';

function useSnapshotHandler(steps, currentStepIndex, updateWizardData, snapshotPath, history) {
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useContext(TranslatorContext);

    const {
        fnolContextState,
        setFnolContextState
    } = useContext(FNOLContext);

    const resetValuesToSnapshotIfNeeded = useCallback((currentDTOValue, isDTOChange, setIsLoading, allowToByPassSnapshotRestore = false) => {
        const nextStepVisited = steps[currentStepIndex + 1].visited;
        const notAllowedToBypassSnapshot = allowToByPassSnapshotRestore === false;
        const rollbackNotAlreadySetted = currentDTOValue.value.userRolledBackData !== true;

        if (nextStepVisited 
            && notAllowedToBypassSnapshot
            && rollbackNotAlreadySetted) {

            const stepSnapshotsAreNotEmpty = !_.isEmpty(Object.keys(fnolContextState.stepSnapshots));
            const snapshotExists = stepSnapshotsAreNotEmpty && fnolContextState.stepSnapshots[snapshotPath]
            if (snapshotExists) {
                // That path means that restore from the snapshot was required and appropriate snapshot was found in fnolContextState
                const { fnolContext, wizardDataSnapshot } = fnolContextState.stepSnapshots[snapshotPath];

                const referenceWizardSnapshot = viewModelService.create(
                    wizardDataSnapshot,
                    'cc',
                    'com.inlb.cc.extsys.edge.anonymousfnol.fnol.dto.FnolDTO'
                );
                _.set(referenceWizardSnapshot.value, 'userRolledBackData', true);

                // Assumption is that if a change is a dto change, updateWizardData will be called just after leaving this page.
                // So the only thing we want to do is make sure that fnolContextState is also updated.
                // It works the same way other direction.
                if (!isDTOChange) {
                    updateWizardData(referenceWizardSnapshot);
                }

                if (isDTOChange) {
                    setFnolContextState({
                        ...fnolContext
                    });
                }

                return {
                    fnolContextState: fnolContext,
                    currentDTOValue: referenceWizardSnapshot
                };
            } else {
                console.log("Save and Resume missing restore scenario reached");
                // That path means that restoration from snapshot was required but appropriate snapshot couldn't be found.
                // Therefore we need to rollback all details filled in by the user.
                // That is a case for example in save and resume context. 
                
                return ModalNextProvider.showConfirm({
                    title: translator(nnCustomMessages.saveAndResumeDraftClaimTitle),
                    message: translator(nnCustomMessages.saveAndResumeDraftClaimBody),
                    cancelButtonText: translator(nnCustomMessages.saveAndResumeDraftClaimConfirm),
                    confirmButtonText: translator(nnCustomMessages.saveAndResumeDraftClaimCancel),
                    status: 'warning',
                    icon: 'mi-error-outline'
                }).then((response) => {
                    if (response === 'cancel') {
                        setIsLoading(true);
                        const policySearchDTO = {
                            policyTypedManually: false,
                            policyNumber: currentDTOValue.value.policyNumber,
                            lossDate: currentDTOValue.value.lossDate,
                            reporterDetails: currentDTOValue.value.reporterDetails,
                            authRequestDetails: currentDTOValue.value.authRequestDetails
                        }
    
                        FNOLService.initializeAuthenticatedUserFlow(policySearchDTO, history, { }).then(
                            (resultDTO) => {
                                if (!resultDTO) return;
                                if (!_.isEmpty(resultDTO.error)) {
                                    redirectToAuthContactCallCenter(history);
                                } else {
                                    return resultDTO
                                }
                            }).then((response) => {
                                history.push({
                                    pathname: '/fnol',
                                    state: {
                                        redirectPath: '/',
                                        fnolDto: response,
                                        forceReload: true
                                    }
                                });
                            }).finally(() => {
                                setIsLoading(false);
                            })
                    } else {
                        return {}
                    }
                }) 
            }
        }
        // That path means that no restore from snapshot was required
        return {
            fnolContextState: fnolContextState,
            currentDTOValue: currentDTOValue
        };
    }, [fnolContextState, setFnolContextState]);

    const prepareSnapshot = useCallback((wizardDataDTO) => {
        // Purpose of this condition is to not create snapshots when customer views past pages in Save And Resume scenario.
        const nextStepAlreadyVisited = steps[currentStepIndex + 1].visited
        if (nextStepAlreadyVisited === false) {
            const fnolState = { ...fnolContextState };

            const referenceWizardSnapshot = viewModelService.create(
                wizardDataDTO,
                'cc',
                'com.inlb.cc.extsys.edge.anonymousfnol.fnol.dto.FnolDTO'
            ).value;

            if (!fnolState.stepSnapshots) {
                fnolState.stepSnapshots = {};
            }

            fnolState.stepSnapshots[snapshotPath] = {
                fnolContext: fnolContextState,
                wizardDataSnapshot: referenceWizardSnapshot
            };

            setFnolContextState(fnolState);
        }
    }, [fnolContextState]);

    return [resetValuesToSnapshotIfNeeded, prepareSnapshot];
}

export default useSnapshotHandler;
