import _defineProperty2 from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty2(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";

function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();

  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
        result;

    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;

      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }

    return _possibleConstructorReturn(this, result);
  };
}

function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;

  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { log } from '@jutro/logger';
import cx from 'classnames';
import { Button } from '@jutro/components';
import { intlMessageShape, intlToShape } from '@jutro/prop-types';
import { TranslatorContext, getUrlTranslatorAndSanitizer } from '@jutro/locale';
import styles from "./ButtonLink.module.css";
export var ButtonLinkInternal = function (_Component) {
  _inherits(ButtonLinkInternal, _Component);

  var _super = _createSuper(ButtonLinkInternal);

  function ButtonLinkInternal() {
    var _this;

    _classCallCheck(this, ButtonLinkInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _defineProperty(_assertThisInitialized(_this), "translator", _this.context);

    _defineProperty(_assertThisInitialized(_this), "urlTranslatorAndSanitizer", getUrlTranslatorAndSanitizer(_this.translator));

    _defineProperty(_assertThisInitialized(_this), "handleClick", function (event) {
      var _this$props = _this.props,
          onClick = _this$props.onClick,
          target = _this$props.target,
          href = _this$props.href;

      if (onClick) {
        onClick(event);
      }

      if (href) {
        return;
      }

      if (!event.defaultPrevented && (event.button === undefined || event.button === 0) && !target) {
        event.preventDefault();
        var history = _this.props.history;
        if (!history) return;
        var _this$props2 = _this.props,
            replace = _this$props2.replace,
            to = _this$props2.to;

        var safeTo = _this.urlTranslatorAndSanitizer(to, _this.props.allowNoLeadingSlash);

        if (replace) {
          history.replace(safeTo);
        } else {
          history.push(safeTo);
        }
      }
    });

    return _this;
  }

  _createClass(ButtonLinkInternal, [{
    key: "render",
    value: function render() {
      var _this$props3 = this.props,
          onClick = _this$props3.onClick,
          replace = _this$props3.replace,
          to = _this$props3.to,
          href = _this$props3.href,
          innerRef = _this$props3.innerRef,
          className = _this$props3.className,
          staticContext = _this$props3.staticContext,
          allowNoLeadingSlash = _this$props3.allowNoLeadingSlash,
          other = _objectWithoutProperties(_this$props3, ["onClick", "replace", "to", "href", "innerRef", "className", "staticContext", "allowNoLeadingSlash"]);

      if (!to && !href) {
        log.error('"href" or "to". One of these properties is required for the component ButtonLink. Use "to" for internal routing and "href" for external routing');
      }

      var classes = cx(styles.buttonLink, className);
      return React.createElement(Button, _extends({
        href: href,
        onClick: this.handleClick
      }, other, {
        ref: innerRef,
        className: classes
      }));
    }
  }]);

  return ButtonLinkInternal;
}(Component);

_defineProperty(ButtonLinkInternal, "propTypes", {
  replace: PropTypes.bool,
  to: intlToShape,
  href: intlMessageShape,
  innerRef: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  children: PropTypes.oneOfType([PropTypes.node, intlMessageShape]),
  onClick: PropTypes.func,
  className: PropTypes.string,
  allowNoLeadingSlash: PropTypes.bool
});

_defineProperty(ButtonLinkInternal, "displayName", 'ButtonLink');

_defineProperty(ButtonLinkInternal, "defaultProps", _objectSpread({}, Button.defaultProps, {
  replace: false,
  allowNoLeadingSlash: false
}));

_defineProperty(ButtonLinkInternal, "contextType", TranslatorContext);

export var ButtonLink = withRouter(ButtonLinkInternal);
ButtonLinkInternal.__docgenInfo = {
  componentName: "ButtonLink",
  packageName: "@jutro/router",
  description: "ButtonLink is an Jutro component,",
  displayName: "ButtonLink",
  methods: [{
    name: "handleClick",
    docblock: "'onClick' callback to invoke callback and continue navigation\n\n@param {Event} event - event object",
    modifiers: [],
    params: [{
      name: "event",
      description: "event object",
      type: {
        name: "Event"
      },
      optional: false
    }],
    returns: null,
    description: "'onClick' callback to invoke callback and continue navigation"
  }],
  actualName: "ButtonLinkInternal",
  metadataType: "action",
  props: {
    replace: {
      type: {
        name: "bool"
      },
      required: false,
      description: "The replace prop will replace the current entry in the history stack",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    to: {
      type: {
        name: "custom",
        raw: "intlToShape"
      },
      required: false,
      description: "The destination path when promise is resolved; can be an object like <Link to>.\nUse this for paths internal to the application."
    },
    href: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "The destination path when promise is resolved;\nUse this for paths external to the application."
    },
    innerRef: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "func"
        }]
      },
      required: false,
      description: "Get ref to the inner rendered <a> or <button>"
    },
    children: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: false
              },
              defaultMessage: {
                name: "string",
                required: false
              },
              args: {
                name: "shape",
                value: {},
                required: false
              }
            }
          }]
        }]
      },
      required: false,
      description: "The child element wrapped in the component"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback when button is clicked"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    },
    allowNoLeadingSlash: {
      type: {
        name: "bool"
      },
      required: false,
      description: "",
      defaultValue: {
        value: "false",
        computed: false
      }
    }
  }
};