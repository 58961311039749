import _defineProperty from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _Number$isNaN from "@babel/runtime-corejs3/core-js-stable/number/is-nan";
import isNil from "lodash/isNil";
import isString from "lodash/isString";
import isNumber from "lodash/isNumber";
import isDate from "lodash/isDate";
import { parseDateShapeToDate, formatDateToDataType } from '@jutro/components';

function getDefaultDate() {
  var today = new Date();
  return {
    year: today.getFullYear(),
    month: today.getMonth(),
    day: today.getDate(),
    hour: 0,
    minute: 0
  };
}

export function formatValueToDataType(value, dataType, includeTime) {
  if (!value) {
    return undefined;
  }

  var _getDefaultDate$value = _objectSpread({}, getDefaultDate(), {}, value),
      year = _getDefaultDate$value.year,
      month = _getDefaultDate$value.month,
      day = _getDefaultDate$value.day,
      hour = _getDefaultDate$value.hour,
      minute = _getDefaultDate$value.minute;

  var date = new Date(year, month, day, hour, minute);
  return formatDateToDataType(date, dataType, includeTime);
}
export function formatValueFromDataType(value, includeTime) {
  if (!value) {
    return undefined;
  }

  var valueToParse = value;

  if (isString(value) || isNumber(value) || isDate(value)) {
    var date = new Date(value);
    valueToParse = {
      year: date.getUTCFullYear(),
      month: date.getUTCMonth(),
      day: date.getUTCDate(),
      hour: date.getUTCHours(),
      minute: date.getUTCMinutes()
    };
  }

  var parsedValue = parseDateShapeToDate(valueToParse, includeTime);

  if (_Number$isNaN(Number(parsedValue.getTime()))) {
    return undefined;
  }

  return {
    year: parsedValue.getFullYear(),
    month: parsedValue.getMonth(),
    day: parsedValue.getDate(),
    hour: parsedValue.getHours(),
    minute: parsedValue.getMinutes()
  };
}
export function isValidDate(value) {
  if (!value) {
    return false;
  }

  var year = value.year,
      month = value.month,
      day = value.day;
  return !isNil(year) && !isNil(month) && !isNil(day);
}
export function isValidTime(value) {
  if (!value) {
    return false;
  }

  var hour = value.hour,
      minute = value.minute;
  return !isNil(hour) && !isNil(minute);
}