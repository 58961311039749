import _ from 'lodash';
import React from 'react';
import { CurrencyValue, FieldComponent } from '@jutro/components';
import { currencyFieldStyles, inputFieldStyles } from './styles';
import CurrencyInputInternal from './CurrencyInputInternal';
import styles from './CurrencyInput.module.scss';

class CurrencyInputComponent extends FieldComponent {
    constructor(props) {
        super(props);
        this.decimalSeparator = props.decimalSeparator;
    }

    handleChange = (value) => {
        this.notifyChange(value);
    }

    parseOrDefaultValue = (valueToParse) => {
        if (!valueToParse || !valueToParse.amount) {
            return 0.00;
        }

        return valueToParse.amount;
    };

    renderControlReadOnly = (breakpointProps) => {
        const {
            id,
            value,
            currency,
            currencyDisplay
        } = breakpointProps;

        if (_.isNil(value)) {
            return '-';
        }

        return (
            <CurrencyValue
                id={id}
                currency={currency}
                currencyDisplay={currencyDisplay}
                placeholder="0.00"
                showFractions
                showGrouping
                tag="div"
                value={this.parseOrDefaultValue(value)}
            />
        );
    }

    renderControl = (breakpointProps) => {
        const combinedStyles = { ...currencyFieldStyles, ...inputFieldStyles };
        const {
            id,
            allowNegativeValue,
            disabled,
            currency,
            localeCode,
            decimalsLimit,
            decimalSeparator,
            groupSeparator,
            required,
            value,
            showErrors
        } = breakpointProps;

        return (
            <div>
                <CurrencyInputInternal
                    allowNegativeValue={allowNegativeValue}
                    id={id}
                    className={combinedStyles}
                    currency={currency}
                    decimalsLimit={decimalsLimit}
                    decimalSeparator={decimalSeparator}
                    disabled={disabled}
                    required={required}
                    value={value}
                    localeCode={localeCode}
                    groupSeparator={groupSeparator}
                    onValueChange={this.handleChange}
                    parseOrDefaultValue={this.parseOrDefaultValue}
                    showErrors={showErrors}
                />
            </div>
        );
    }
}

CurrencyInputComponent.defaultProps = {
    ...FieldComponent.defaultProps,
    allowNegativeValue: false,
    currency: 'EUR',
    decimalsLimit: 2,
    decimalSeparator: ',',
    groupSeparator: '.',
    required: false,
    currencyDisplay: 'symbol'
};

export default CurrencyInputComponent;
