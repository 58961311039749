import _defineProperty2 from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";

function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();

  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
        result;

    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;

      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }

    return _possibleConstructorReturn(this, result);
  };
}

function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;

  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';
import { intlMessageShape } from '@jutro/prop-types';
import { defaultTheme } from '@jutro/theme';
import styles from "./FieldMessage.module.css";
export var FieldMessage = function (_PureComponent) {
  _inherits(FieldMessage, _PureComponent);

  var _super = _createSuper(FieldMessage);

  function FieldMessage() {
    _classCallCheck(this, FieldMessage);

    return _super.apply(this, arguments);
  }

  _createClass(FieldMessage, [{
    key: "render",
    value: function render() {
      var _cx;

      var _this$props = this.props,
          id = _this$props.id,
          translator = _this$props.translator,
          errorMessage = _this$props.errorMessage,
          successMessage = _this$props.successMessage;
      var classes = cx(styles.fieldMessage, (_cx = {}, _defineProperty2(_cx, styles.error, errorMessage), _defineProperty2(_cx, styles.success, !errorMessage && successMessage), _cx));
      var message = errorMessage || successMessage;
      return React.createElement("div", {
        id: id,
        key: id,
        className: classes,
        role: "alert",
        "aria-hidden": !message
      }, _.isFunction(message) ? message() : translator(message));
    }
  }]);

  return FieldMessage;
}(PureComponent);

_defineProperty(FieldMessage, "propTypes", {
  id: PropTypes.string.isRequired,
  errorMessage: PropTypes.oneOfType([PropTypes.func, intlMessageShape]),
  successMessage: PropTypes.oneOfType([PropTypes.func, intlMessageShape]),
  theme: PropTypes.object,
  translator: PropTypes.func
});

_defineProperty(FieldMessage, "defaultProps", {
  theme: defaultTheme
});

FieldMessage.__docgenInfo = {
  componentName: "FieldMessage",
  packageName: "@jutro/components",
  description: "Class renders messages for `FieldComponent`.\nValidation messages are expected to be translated before they are passed.",
  displayName: "FieldMessage",
  methods: [],
  actualName: "FieldMessage",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "Component unique identifier"
    },
    errorMessage: {
      type: {
        name: "union",
        value: [{
          name: "func"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: false
              },
              defaultMessage: {
                name: "string",
                required: false
              },
              args: {
                name: "shape",
                value: {},
                required: false
              }
            }
          }]
        }]
      },
      required: false,
      description: "Error message to display"
    },
    successMessage: {
      type: {
        name: "union",
        value: [{
          name: "func"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: false
              },
              defaultMessage: {
                name: "string",
                required: false
              },
              args: {
                name: "shape",
                value: {},
                required: false
              }
            }
          }]
        }]
      },
      required: false,
      description: "Success message to display"
    },
    theme: {
      type: {
        name: "object"
      },
      required: false,
      description: "Theme to apply to component",
      defaultValue: {
        value: "defaultTheme",
        computed: true
      }
    },
    translator: {
      type: {
        name: "func"
      },
      required: false,
      description: "The function used to translate strings"
    }
  }
};