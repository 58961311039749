import _toConsumableArray from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/toConsumableArray";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _Promise from "@babel/runtime-corejs3/core-js-stable/promise";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import RedBox from 'redbox-react';
import { loadConfiguration, getConfigValue } from '@jutro/config';
import { initDefaultGA, initDefaultMixpanel, initDefaultSumoLogic, initDefaultDataDog } from '@jutro/events';
import { logDeprecationMessage } from '@jutro/platform';
import { ThemeProvider } from '@jutro/theme';
import { hot } from 'react-hot-loader';
import { warning } from '@jutro/logger';
import { ApplicationRoot } from './ApplicationRoot';
import { InitLoading } from './InitLoading';

var initAnalytics = function initAnalytics(trackingConfig) {
  var gaTrackingId = getConfigValue('GA_TRACKING_ID');
  var mixpanelTrackingId = getConfigValue('MIXPANEL_TRACKING_ID');
  var dataDogClientToken = getConfigValue('JUTRO_DATA_DOG_CLIENT_TOKEN');
  var sumoLogicUrl = getConfigValue('JUTRO_SUMO_LOGIC_URL');

  if (!(gaTrackingId || mixpanelTrackingId || dataDogClientToken || sumoLogicUrl)) {
    warning("To enable Jutro analytics you need to provide a gaTrackingId, mixpanelTrackingId, dataDogClientToken , sumoLogicUrl  using env vars (GA_TRACKING_ID, MIXPANEL_TRACKING_ID, JUTRO_DATA_DOG_CLIENT_TOKEN or JUTRO_SUMO_LOGIC_URL). More details about tracking can be found here - https://jutro-master.int.ccs.guidewire.net/jutro-storybook/?path=/docs/reference-features-analytics--page");
  }

  if (sumoLogicUrl) {
    logDeprecationMessage('SumoLogic', 'DataDog', undefined, '6.0');
    initDefaultSumoLogic(trackingConfig);
  }

  if (dataDogClientToken) {
    initDefaultDataDog(trackingConfig);
  }

  if (gaTrackingId) {
    initDefaultGA(trackingConfig);
  }

  if (mixpanelTrackingId) {
    initDefaultMixpanel(trackingConfig);
  }
};

export var start = function start(Main) {
  var launchProps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _launchProps$config = launchProps.config,
      config = _launchProps$config === void 0 ? [] : _launchProps$config,
      trackingConfig = launchProps.trackingConfig,
      rootId = launchProps.rootId,
      themeConfig = launchProps.themeConfig,
      _launchProps$themePro = launchProps.themeProviderProps,
      themeProviderProps = _launchProps$themePro === void 0 ? {} : _launchProps$themePro,
      disableThemeProvider = launchProps.disableThemeProvider,
      _launchProps$LoadingC = launchProps.LoadingComponent,
      LoadingComponent = _launchProps$LoadingC === void 0 ? InitLoading : _launchProps$LoadingC,
      _launchProps$ErrorLoa = launchProps.ErrorLoadingComponent,
      ErrorLoadingComponent = _launchProps$ErrorLoa === void 0 ? RedBox : _launchProps$ErrorLoa,
      _launchProps$onInit = launchProps.onInit,
      onInit = _launchProps$onInit === void 0 ? function () {} : _launchProps$onInit,
      onRender = launchProps.onRender,
      appProps = _objectWithoutProperties(launchProps, ["config", "trackingConfig", "rootId", "themeConfig", "themeProviderProps", "disableThemeProvider", "LoadingComponent", "ErrorLoadingComponent", "onInit", "onRender"]);

  loadConfiguration.apply(void 0, _toConsumableArray(config));
  initAnalytics(trackingConfig);
  var LoadableRoot = Loadable.Map({
    loader: {
      init: function init() {
        return _Promise.resolve(onInit());
      }
    },
    loading: function loading(props) {
      if (props.error) {
        return React.createElement(ErrorLoadingComponent, {
          error: props.error
        });
      }

      return React.createElement(LoadingComponent, null);
    },
    render: function render() {
      return React.createElement(ApplicationRoot, _extends({
        main: Main
      }, appProps));
    }
  });
  var Root = false ? hot(module)(LoadableRoot) : LoadableRoot;
  var WrappedComponent = disableThemeProvider ? React.createElement(Root, null) : React.createElement(ThemeProvider, _extends({
    initialConfig: themeConfig
  }, themeProviderProps), React.createElement(Root, null));
  ReactDOM.render(WrappedComponent, document.getElementById(rootId), onRender);
};