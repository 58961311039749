import _defineProperty from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from 'react';
import cx from 'classnames';
import { components } from 'react-select';
export var GenericSelectControlOption = function GenericSelectControlOption(props) {
  var _cx;

  var genericSelectStyles = props.selectProps.genericSelectStyles;
  var optionClasses = cx(genericSelectStyles.option, (_cx = {}, _defineProperty(_cx, genericSelectStyles.optionFocused, props.isFocused), _defineProperty(_cx, genericSelectStyles.optionSelected, props.isSelected), _cx));

  var innerProps = _objectSpread({
    role: 'option'
  }, props.isSelected && {
    'aria-selected': 'true'
  }, {}, props.innerProps);

  return React.createElement(components.Option, _extends({}, props, {
    className: optionClasses,
    innerProps: innerProps
  }));
};
GenericSelectControlOption.__docgenInfo = {
  componentName: "GenericSelectControlOption",
  packageName: "@jutro/components",
  description: "",
  displayName: "GenericSelectControlOption",
  methods: [],
  actualName: "GenericSelectControlOption"
};