import _defineProperty from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import cx from 'classnames';
import styles from "./StickyFooter.module.css";
var stickyFooterPropTypes = {
  children: PropTypes.node.isRequired,
  hiddenOnLoad: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  stickyClassName: PropTypes.string,
  containerClassName: PropTypes.string
};
export var StickyFooter = function StickyFooter(_ref) {
  var children = _ref.children,
      hiddenOnLoad = _ref.hiddenOnLoad,
      fullWidth = _ref.fullWidth,
      className = _ref.className,
      stickyClassName = _ref.stickyClassName,
      containerClassName = _ref.containerClassName;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isInitialView = _useState2[0],
      setInitialView = _useState2[1];

  var _useInView = useInView({
    threshold: 1
  }),
      _useInView2 = _slicedToArray(_useInView, 3),
      ref = _useInView2[0],
      inView = _useInView2[1],
      entry = _useInView2[2];

  var elementDissapeardOnBottom = entry ? entry.boundingClientRect.top > 0 && !entry.isIntersecting : true;
  var shouldDisplaySticky = !inView && elementDissapeardOnBottom && (!hiddenOnLoad || isInitialView);
  var stickyClasses = cx(styles.stickyFooter, _defineProperty({}, styles.fullWidth, fullWidth), className, stickyClassName);

  if (!isInitialView && inView) {
    setInitialView(true);
  }

  var childrenContainer = React.createElement("div", {
    className: containerClassName
  }, children);
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: className,
    ref: ref
  }, childrenContainer), shouldDisplaySticky && React.createElement("div", {
    className: stickyClasses
  }, childrenContainer));
};
StickyFooter.propTypes = stickyFooterPropTypes;
StickyFooter.defaultProps = {
  hiddenOnLoad: true,
  fullWidth: true
};
StickyFooter.__docgenInfo = {
  componentName: "StickyFooter",
  packageName: "@jutro/components",
  description: "StickyFooter",
  displayName: "StickyFooter",
  methods: [],
  actualName: "StickyFooter",
  metadataType: "container",
  props: {
    children: {
      type: {
        name: "node"
      },
      required: true,
      description: "Footer's content"
    },
    hiddenOnLoad: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Sticky version is displayed only after initial view of children",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    fullWidth: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If `fullWidth` is set then sticky version expands to full width of the screen using `position: fixed`,\notherwise it fills the width of its parent using `position: sticky`",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class name for component."
    },
    stickyClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class name for component applied only on sticky version."
    },
    containerClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class name for children container component."
    }
  }
};