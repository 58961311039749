import _defineProperty from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _Array$isArray from "@babel/runtime-corejs3/core-js-stable/array/is-array";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _Object$assign from "@babel/runtime-corejs3/core-js-stable/object/assign";
import _JSON$stringify from "@babel/runtime-corejs3/core-js-stable/json/stringify";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _forEachInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/for-each";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import React from 'react';
import isObject from "lodash/isObject";
import isArray from "lodash/isArray";
import isString from "lodash/isString";
import omitBy from "lodash/omitBy";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import get from "lodash/get";
import { log, makeUniqueWarningLog } from '@jutro/logger';
import { ResponsiveElement, IntlElement } from '@jutro/components';
import { isSupportedHTMLElement, isSupportedHTMLContainer, iterateMetadata, SchemaValidator, metadataSchema, resolveContentReference } from '@jutro/uimetadata';
import { removeDangerousProps } from '@jutro/platform';
import { resolveComponentFromDatatype, resolveComponentFromName } from './componentMap';
import { UnresolvedComponent } from './UnresolvedComponent/UnresolvedComponent';
var schemaValidator = new SchemaValidator(metadataSchema);
var callbackRegex = /^(on|render)[A-Z]/;
var classNameRegex = /^className$|\w+ClassName$/;
export function resolveCallbackProps(props, callbackMap) {
  return resolvePropsWithMap(props, callbackMap, isValidCallbackProp, resolveCallback);
}
export function resolveClassNamesProps(props, classNameMap) {
  return resolvePropsWithMap(props, classNameMap, isValidClassNameProp, resolveClassNames);
}
export function resolveProps(props, callbackMap, classNameMap) {
  return resolveCallbackProps(resolveClassNamesProps(props, classNameMap), callbackMap);
}

var resolvePropsWithMap = function resolvePropsWithMap(props, map, matcher, resolver) {
  var _context;

  return _reduceInstanceProperty(_context = _Object$keys(props)).call(_context, function (result, key) {
    var originalValue = props[key],
        id = props.id;
    var newValue = matcher(key, originalValue) ? resolver(id, originalValue, map) : originalValue;
    return _objectSpread({}, result, _defineProperty({}, key, newValue));
  }, {});
};

function isValidCallbackProp(callbackProp, callbackValue) {
  if (!callbackProp.match(callbackRegex)) return false;
  if (!callbackValue) return false;
  return isString(callbackValue) || isObject(callbackValue) && callbackValue.callback;
}

function isValidClassNameProp(classNameProp, classNameValue) {
  if (!classNameProp.match(classNameRegex)) return false;
  if (!classNameValue) return false;
  return isString(classNameValue);
}

function mapLookup(map, key) {
  var defaultValue = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : undefined;

  if (!key) {
    return undefined;
  }

  return map && map[key] || defaultValue;
}

export function resolveClassName(id, className, classNameMap) {
  return mapLookup(classNameMap, className, className);
}
export function resolveClassNames(id, classNames, classNameMap) {
  var resolver = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : resolveClassName;
  var oldNames = classNames.split(' ');

  var newNames = _mapInstanceProperty(oldNames).call(oldNames, function (className) {
    return resolver(id, className, classNameMap);
  });

  return newNames.join(' ');
}
export function resolveCallback(id, callbackName, callbackMap) {
  return mapLookup(callbackMap, callbackName);
}
var deprecationWarningForComponentCasing = makeUniqueWarningLog('Support for componentMaps with case-insensitive keys has been deprecated. Adjust the keys in your componentMap to match the name of the component');
export function resolveComponent(component, datatype, componentMap) {
  if (!component && !datatype) {
    return undefined;
  }

  var componentInfo;

  if (!componentInfo && component && !isString(component)) {
    componentInfo = {
      component: component
    };
  }

  if (isString(component)) {
    if (!componentInfo && componentMap) {
      componentInfo = mapLookup(componentMap, component);
    }

    if (!componentInfo && componentMap) {
      componentInfo = mapLookup(componentMap, component.toLowerCase());

      if (componentInfo) {
        deprecationWarningForComponentCasing();
      }
    }

    if (!componentInfo) {
      componentInfo = resolveComponentFromName(component);
    }

    if (!componentInfo) {
      componentInfo = {
        component: component
      };
    }
  }

  if (isString(datatype)) {
    if (!componentInfo && componentMap) {
      componentInfo = mapLookup(componentMap, datatype.toLowerCase());
    }

    if (!componentInfo) {
      componentInfo = resolveComponentFromDatatype(datatype, false);
    }

    if (!componentInfo) {
      log.warning("unable to resolve component by datatype: \"".concat(datatype, "\""));
    }
  }

  return componentInfo;
}

function getContentArray(contentMetadata) {
  if (isArray(contentMetadata)) {
    return contentMetadata;
  }

  if (isObject(contentMetadata) && contentMetadata.content && isArray(contentMetadata.content)) {
    return contentMetadata.content;
  }

  return [];
}

export function prepareComponentFromMetadata(metadata, overrideProps) {
  var resolvers = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var rootMetadata = arguments.length > 3 ? arguments[3] : undefined;
  var metadataPath = arguments.length > 4 ? arguments[4] : undefined;

  var _context2;

  if (!metadata) {
    return null;
  }

  if (React.isValidElement(metadata)) {
    return metadata;
  }

  var id = metadata.id,
      type = metadata.type,
      datatype = metadata.datatype,
      component = metadata.component,
      _metadata$componentPr = metadata.componentProps,
      componentProps = _metadata$componentPr === void 0 ? {} : _metadata$componentPr,
      content = metadata.content,
      contentAfter = metadata.contentAfter,
      contentBefore = metadata.contentBefore,
      contentEmpty = metadata.contentEmpty,
      contentRepeat = metadata.contentRepeat,
      contentRepeatAfter = metadata.contentRepeatAfter,
      contentRepeatBefore = metadata.contentRepeatBefore,
      contentLayout = metadata.contentLayout,
      selfLayout = metadata.selfLayout;

  var _prepareOverrides = prepareOverrides(id, type, componentProps.path, overrideProps),
      contentOverride = _prepareOverrides.content,
      propOverrides = _objectWithoutProperties(_prepareOverrides, ["content"]);

  var additionalProps = propOverrides;
  var dataProps;
  var baseDatatype;
  var baseComponent;
  var fieldPath = additionalProps && additionalProps.path || componentProps && componentProps.path;
  var hasDataProps = _includesInstanceProperty(_context2 = ['element', 'field', 'container', 'iterable', 'layout']).call(_context2, type) && fieldPath;

  if (hasDataProps && resolvers.resolveDataProps) {
    var resolvedDataProps = resolvers.resolveDataProps(id, fieldPath);

    if (resolvedDataProps) {
      baseDatatype = resolvedDataProps.datatype;
      baseComponent = resolvedDataProps.component;
      dataProps = resolvedDataProps.componentProps;
    }
  }

  var componentInfo = prepareComponentInfo(component || baseComponent, datatype || baseDatatype, type, content, _objectSpread({}, dataProps, {}, componentProps, {}, additionalProps), resolvers, metadataPath);
  var Component = componentInfo && componentInfo.component;
  var componentMapProps = componentInfo && componentInfo.componentProps;

  if (resolvers.resolveCallbackMap) {
    additionalProps = prepareCallbackOverrides(componentProps, additionalProps);
  }

  var combinedProps = removeDangerousProps(_objectSpread({}, dataProps, {}, componentMapProps, {}, componentProps, {}, additionalProps));
  var children = contentOverride || content;
  var resolvedContent = contentOverride || resolveContent(metadata, resolvers);

  if (type === 'iterable' && contentRepeat && combinedProps.visible !== false) {
    var repeatPath = contentRepeat.path || combinedProps.path;
    var repeatData = contentRepeat.data || combinedProps.data || resolvers.resolveValue && resolvers.resolveValue(id, repeatPath);
    resolvedContent = iterateMetadata({
      contentAfter: contentAfter,
      contentRepeatAfter: contentRepeatAfter,
      contentBefore: contentBefore,
      contentRepeatBefore: contentRepeatBefore,
      contentEmpty: contentEmpty,
      contentRepeat: contentRepeat,
      repeatData: repeatData,
      repeatPath: repeatPath
    });
  }

  var preparedChildrenLayout;

  if (isArray(resolvedContent)) {
    var preparedContent = prepareContentFromMetadata({
      content: resolvedContent,
      contentLayout: contentLayout
    }, overrideProps, resolvers, rootMetadata, metadataPath);
    children = preparedContent.content;
    preparedChildrenLayout = preparedContent.contentLayout;
  }

  if (Component !== undefined) {
    prepareComponentProps(id, type, Component, combinedProps, resolvers);
  }

  var preparedSelfLayout = selfLayout ? prepareLayout(selfLayout, resolvers) : undefined;
  var result = {
    id: id,
    type: type,
    datatype: datatype,
    component: Component,
    componentProps: !isEmpty(combinedProps) ? combinedProps : undefined,
    content: children,
    contentLayout: preparedChildrenLayout,
    selfLayout: preparedSelfLayout
  };
  return omitBy(result, isUndefined);
}

function prepareLayout(layout, resolvers) {
  var componentInfo = prepareComponentInfo(layout.component, undefined, 'layout', undefined, undefined, resolvers);

  if (resolvers) {
    prepareComponentProps(null, null, layout.component, layout.componentProps, resolvers);
  }

  return _objectSpread({}, layout, {
    componentProps: removeDangerousProps(layout.componentProps),
    component: componentInfo && componentInfo.component
  });
}

function prepareComponentInfo(component, datatype, type, content, componentProps, resolvers, metadataPath) {
  var transformComponentMap;

  if (isString(component) && type === 'element' && isSupportedHTMLElement(component)) {
    var hasIntlContent = content && content.id !== undefined && content.defaultMessage !== undefined;

    var _ref = componentProps || {},
        phone = _ref.phone,
        phoneWide = _ref.phoneWide,
        tablet = _ref.tablet;

    var hasBreakpointProps = phone || phoneWide || tablet;

    if (hasBreakpointProps && hasIntlContent) {
      transformComponentMap = _defineProperty({}, component, {
        component: ResponsiveElement,
        componentProps: {
          tag: component,
          parentTag: IntlElement
        }
      });
    } else if (hasIntlContent) {
      transformComponentMap = _defineProperty({}, component, {
        component: IntlElement,
        componentProps: {
          tag: component
        }
      });
    } else if (hasBreakpointProps) {
      transformComponentMap = _defineProperty({}, component, {
        component: ResponsiveElement,
        componentProps: {
          tag: component
        }
      });
    }
  }

  var componentResolver = resolvers && resolvers.resolveComponent || resolveComponent;
  var componentMap = resolvers && resolvers.resolveComponentMap;
  var componentInfo = componentResolver(component, datatype, transformComponentMap ? _objectSpread({}, transformComponentMap, {}, componentMap) : componentMap, type, metadataPath, componentProps);

  if (componentInfo && !componentInfo.component) {
    return {
      component: componentInfo
    };
  }

  return componentInfo;
}

function prepareOverrides(id, type, path, overrideProps) {
  var additionalProps = {};

  var resolveOverrides = function resolveOverrides(key) {
    var overrides = overrideProps[key];
    return typeof overrides === 'function' ? overrides(id, path) : overrides;
  };

  if (overrideProps && overrideProps['@all']) {
    additionalProps = _objectSpread({}, additionalProps, {}, resolveOverrides('@all'));
  }

  if (overrideProps && overrideProps["@".concat(type)]) {
    additionalProps = _objectSpread({}, additionalProps, {}, resolveOverrides("@".concat(type)));
  }

  if (overrideProps && overrideProps[id]) {
    additionalProps = _objectSpread({}, additionalProps, {}, resolveOverrides(id));
  }

  return additionalProps;
}

function prepareCallbackOverrides(componentProps, additionalProps) {
  var resolvedProps = additionalProps;

  if (componentProps) {
    var _context3;

    _forEachInstanceProperty(_context3 = _Object$keys(componentProps)).call(_context3, function (callbackProp) {
      var callbackValue = componentProps[callbackProp];

      if (isValidCallbackProp(callbackProp, callbackValue)) {
        resolvedProps[callbackProp] = callbackValue;
      }
    });
  }

  return resolvedProps;
}

function prepareComponentProps(id, type, component, props, resolvers) {
  var componentProps = props;

  var _ref2 = componentProps || {},
      path = _ref2.path,
      startPath = _ref2.startPath,
      endPath = _ref2.endPath;

  var _ref3 = (component === null || component === void 0 ? void 0 : component.defaultProps) || {},
      startPathValueProp = _ref3.startPathValueProp,
      endPathValueProp = _ref3.endPathValueProp;

  if ((type === 'element' || type === 'field') && resolvers.resolveValue) {
    if (path) {
      componentProps.value = resolvers.resolveValue(id, path);
    }

    if (startPath && endPath) {
      var _componentProps$value;

      componentProps.value = (_componentProps$value = {}, _defineProperty(_componentProps$value, startPathValueProp, resolvers.resolveValue("".concat(id, "_").concat(startPathValueProp), startPath)), _defineProperty(_componentProps$value, endPathValueProp, resolvers.resolveValue("".concat(id, "_").concat(endPathValueProp), endPath)), _componentProps$value);
    }
  }

  if ((type === 'container' || type === 'iterable') && path && resolvers.resolveValue) {
    componentProps.data = resolvers.resolveValue(id, path);
  }

  if (type === 'field' && resolvers.resolveDefaultValue) {
    if (path) {
      componentProps.defaultValue = resolvers.resolveDefaultValue(id, path);
    }

    if (startPath && endPath) {
      var _componentProps$defau;

      componentProps.defaultValue = (_componentProps$defau = {}, _defineProperty(_componentProps$defau, startPathValueProp, resolvers.resolveDefaultValue("".concat(id, "_").concat(startPathValueProp), startPath)), _defineProperty(_componentProps$defau, endPathValueProp, resolvers.resolveDefaultValue("".concat(id, "_").concat(endPathValueProp), endPath)), _componentProps$defau);
    }
  }

  if (resolvers.resolveClassName || resolvers.resolveClassNameMap) {
    var classNameResolver = resolvers.resolveClassName || resolveClassName;
    var classNameMap = resolvers.resolveClassNameMap;

    if (componentProps) {
      var _context4, _context5;

      _forEachInstanceProperty(_context4 = _filterInstanceProperty(_context5 = _Object$keys(componentProps)).call(_context5, function (prop) {
        return isValidClassNameProp(prop, componentProps[prop]);
      })).call(_context4, function (classNameProp) {
        componentProps[classNameProp] = resolveClassNames(id, componentProps[classNameProp], classNameMap, classNameResolver);
      });
    }
  }

  prepareCallbacksInComponentProps({
    resolvers: resolvers,
    id: id,
    componentProps: componentProps
  });

  if (type === 'field' && resolvers.resolveValidation) {
    componentProps.validationMessages = resolvers.resolveValidation(id, path, componentProps && componentProps.value);
  }
}

export var prepareCallbacksInComponentProps = function prepareCallbacksInComponentProps(_ref6) {
  var resolvers = _ref6.resolvers,
      id = _ref6.id,
      componentProps = _ref6.componentProps;

  if (resolvers.resolveCallback || resolvers.resolveCallbackMap) {
    var callbackResolver = resolvers.resolveCallback || resolveCallback;
    var callbackMap = resolvers.resolveCallbackMap;

    if (!isEmpty(componentProps)) {
      var _context6;

      var componentPropsWithCallbacks = _filterInstanceProperty(_context6 = _Object$keys(componentProps)).call(_context6, function (callbackProp) {
        return isValidCallbackProp(callbackProp, componentProps[callbackProp]);
      });

      _forEachInstanceProperty(componentPropsWithCallbacks).call(componentPropsWithCallbacks, function (callbackProp) {
        var callbackValue = componentProps[callbackProp];
        var callbackFunction = callbackResolver(id, get(callbackValue, 'callback', callbackValue), callbackMap);

        if (callbackMap && !callbackFunction) {
          log.warning("unable to resolve callback: ".concat(id, " ").concat(_JSON$stringify(callbackValue, null, 2)));
        }

        var wrappedFn = callbackValue.callback && callbackFunction ? function () {
          for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
          }

          return callbackFunction.apply(void 0, [_objectSpread({}, callbackValue.callbackProps)].concat(args));
        } : callbackFunction;
        componentProps[callbackProp] = wrappedFn;
      });

      return _objectSpread({}, componentProps);
    }
  }

  return componentProps;
};
export function prepareContentFromMetadata(contentMetadata, overrideProps, resolvers, rootMetadata) {
  var metadataPath = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '';
  var contentArray = getContentArray(contentMetadata);

  var content = _mapInstanceProperty(contentArray).call(contentArray, function (item, i) {
    var extendedItem = resolveContentReference(item, rootMetadata);
    return prepareComponentFromMetadata(extendedItem, overrideProps, resolvers, rootMetadata, "".concat(metadataPath ? "".concat(metadataPath, ".") : metadataPath, "content.").concat(i));
  });

  var contentLayoutMetadata = contentMetadata && contentMetadata.contentLayout;
  var contentLayout = contentLayoutMetadata ? prepareLayout(contentLayoutMetadata, resolvers) : undefined;
  return {
    content: content,
    contentLayout: contentLayout
  };
}
export function renderComponentFromMetadata(metadata) {
  var showHidden = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  if (!metadata) {
    return null;
  }

  if (React.isValidElement(metadata)) {
    return metadata;
  }

  var id = metadata.id,
      Component = metadata.component,
      componentProps = metadata.componentProps,
      content = metadata.content,
      contentLayout = metadata.contentLayout,
      selfLayout = metadata.selfLayout;

  var _ref4 = componentProps || {},
      visible = _ref4.visible,
      path = _ref4.path;

  var key = id || path;

  if (!showHidden && visible === false) {
    return null;
  }

  var children;

  if (isArray(content)) {
    children = renderContent(content, contentLayout, showHidden);
  } else {
    children = content;
  }

  var renderOutput;
  var componentString = Component && Component.toString();

  var isIntentionalFragment = Component && (_includesInstanceProperty(componentString).call(componentString, 'react.fragment') || _includesInstanceProperty(componentString).call(componentString, 'React.Fragment'));

  if (isIntentionalFragment) {
    renderOutput = React.createElement(React.Fragment, {
      key: id
    }, children);
  } else if (isComponentUnresolved(Component)) {
    renderOutput = React.createElement(UnresolvedComponent, {
      id: id,
      componentName: Component
    });
  } else {
    var sanitizedProps = removeDangerousProps(componentProps);

    var props = _Object$assign(sanitizedProps, {
      resolvers: Component.isCodelessComponent ? sanitizedProps.resolvers : undefined
    });

    renderOutput = React.createElement(Component, _extends({
      id: key,
      key: key
    }, props), children);
  }

  if (selfLayout) {
    return renderLayout(renderOutput, selfLayout);
  }

  return renderOutput;
}
export function renderContent(contentMetadata, contentLayout) {
  var showHidden = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

  var content = _mapInstanceProperty(contentMetadata).call(contentMetadata, function (metadata) {
    return renderComponentFromMetadata(metadata, showHidden);
  });

  if (!isEmpty(contentLayout)) {
    return renderLayout(content, contentLayout);
  }

  return content;
}
export function renderLayout(content, contentLayout) {
  var LayoutComponent = contentLayout.component,
      componentProps = contentLayout.componentProps;

  if (LayoutComponent) {
    return React.createElement(LayoutComponent, removeDangerousProps(componentProps), content);
  }

  return content;
}
export function renderContentFromMetadata(contentMetadata, overrideProps, resolvers) {
  var showHidden = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

  var _prepareContentFromMe = prepareContentFromMetadata(contentMetadata, overrideProps, resolvers, contentMetadata),
      content = _prepareContentFromMe.content,
      _prepareContentFromMe2 = _prepareContentFromMe.contentLayout,
      contentLayout = _prepareContentFromMe2 === void 0 ? {} : _prepareContentFromMe2;

  return renderContent(content, contentLayout, showHidden);
}
export function validateMetadata(metadata, onValidationFailure) {
  var validationResult = schemaValidator.validate(metadata);

  if (!validationResult.valid) {
    if (onValidationFailure) {
      onValidationFailure('Metadata validation failed', 'warning');
    }

    log.warning("Metadata is invalid:\nFor easier debugging consider opening in an IDE (VSCode or Intellij):\n".concat(_JSON$stringify(validationResult.errorMessages)));
  }
}
export function findComponentFromMetadata(metadata, filterFn) {
  var skipHidden = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var list = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];

  if (!metadata) {
    return list;
  }

  var component = metadata.component,
      componentProps = metadata.componentProps,
      content = metadata.content;

  var _ref5 = componentProps || {},
      value = _ref5.value,
      visible = _ref5.visible;

  if (skipHidden && visible === false) {
    return list;
  }

  if (!filterFn || filterFn(metadata)) {
    list.push(metadata);
  }

  if (isArray(content)) {
    if (skipHidden && component && component.isContentVisible) {
      var isContentVisible = component.isContentVisible(value, componentProps);

      if (!isContentVisible) {
        return list;
      }
    }

    findContentFromMetadata(content, filterFn, skipHidden, list);
  }

  return list;
}
export function findContentFromMetadata(contentMetadata, filterFn) {
  var skipHidden = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var list = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  var contentArray = getContentArray(contentMetadata);
  return _reduceInstanceProperty(contentArray).call(contentArray, function (foundArray, item) {
    return findComponentFromMetadata(item, filterFn, skipHidden, foundArray);
  }, list);
}
export function resolveContent(metadata) {
  var _ref7 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      contentResolver = _ref7.resolveContent,
      resolveContentOptions = _ref7.resolveContentOptions;

  var _context7;

  if (!contentResolver && !resolveContentOptions) {
    return metadata.content;
  }

  var content = _reduceInstanceProperty(_context7 = resolveContentOptions || ['content']).call(_context7, function (resolvedContent, path) {
    return resolvedContent || get(metadata, path);
  }, undefined);

  return contentResolver && contentResolver(content) || content;
}
export var isComponentUnresolved = function isComponentUnresolved(Component) {
  return Component === undefined || Component === null || isString(Component) && !(isSupportedHTMLElement(Component) || isSupportedHTMLContainer(Component));
};
export function findComponentPropsById(meta, id) {
  if ((meta === null || meta === void 0 ? void 0 : meta.id) === id) {
    return (meta === null || meta === void 0 ? void 0 : meta.componentProps) || null;
  }

  var content = meta === null || meta === void 0 ? void 0 : meta.content;

  if (_Array$isArray(content)) {
    var _iterator = _createForOfIteratorHelper(content),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var metaItem = _step.value;
        var foundItem = findComponentPropsById(metaItem, id);

        if (foundItem) {
          return foundItem;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  }

  return null;
}