/* eslint-disable max-len */
import _ from 'lodash';
import React, { useMemo, useEffect, useCallback } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import useBreakpointHandler from '../../../../hooks/useBreakpointHandler';
import { isNotEmptyOrZero } from '../../../../utils/CurrencyAmountUtils';
import { isPropertyLOB } from '../../../../utils/PolicyTypeUtil';
import ContentEstimateComponent from '../ContentEstimateComponent/ContentEstimateComponent';
import metadata from './FNOLRepairEstimates.metadata.json5';
import styles from './FNOLRepairEstimates.module.scss';
// eslint-disable-next-line no-unused-vars
import messages from '../EstimateComponents.messages';

function FNOLRepairEstimates(props) {
    const {
        id,
        repairDTO,
        wizardDTO,
        onValidate,
        writeValue,
        showContentEstimateSection
    } = props;

    const [
        isMobileDevice
    ] = useBreakpointHandler();

    const {
        onValidate: onComponentValidate,
        isComponentValid,
        registerComponentValidation
    } = useValidation('FNOLRepairEstimates');

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const componentValidation = useCallback(() => {
        const repairDTOValue = repairDTO.value;
        const isPropertyClaim = isPropertyLOB(wizardDTO.lob.value);
        if (isPropertyClaim && (repairDTOValue.alreadyReceivedEstimate || repairDTOValue.settledViaPVProcedure)) {
            return (repairDTOValue.detailedContentEstimatesPresent === true && !_.isEmpty(repairDTOValue.detailedContentEstimates))
                || (repairDTOValue.detailedContentEstimatesPresent === false && isNotEmptyOrZero(repairDTOValue.estimatedContentRepairCost))
                || isNotEmptyOrZero(repairDTOValue.estimatedBuildingRepairCost);
        }

        if (!isPropertyClaim && repairDTOValue.alreadyReceivedEstimate) {
            return isNotEmptyOrZero(repairDTOValue.estimatedRepairCost);
        }
        return true;
    }, [repairDTO.value, wizardDTO.lob.value]);

    useEffect(() => {
        registerComponentValidation(componentValidation);
    }, [registerComponentValidation, componentValidation]);

    const availableDamageRepairedAnswers = useMemo(() => {
        const values = _.get(repairDTO, 'damageAlreadyRepaired.aspects.availableValues');
        return values.map((typecode) => {
            return {
                code: typecode.code,
                name: {
                    id: typecode.name,
                    defaultMessage: typecode.name
                }
            };
        });
    }, [repairDTO]);

    const mobileOrRegularStyle = useCallback((mobileClassName, className) => {
        return isMobileDevice() ? mobileClassName : className;
    }, [isMobileDevice]);

    const overrideProps = {
        fNOLRepairEstimatesRepairCost: {
            visible: !isPropertyLOB(wizardDTO.lob.value),
            className: mobileOrRegularStyle('phoneRepairCost', 'repairCost')
        },
        fNOLRepairEstimatesBuildingRepairCost: {
            className: mobileOrRegularStyle('phoneRepairCostWithMargin', 'repairCostWithMargin')
        },
        fNOLRepairEstimatesContentRepairCost: {
            className: mobileOrRegularStyle('phoneRepairCostWithMargin', 'repairCostWithMargin')
        },
        fNOLRepairEstimatesReceivedYesBuildingContent: {
            visible: isPropertyLOB(wizardDTO.lob.value)
        },
        fNOLRepairEstimatesReceivedYes: {
            visible: repairDTO.alreadyReceivedEstimate.value === true
        },
        fNOLRepairDamageAlreadyFixed: {
            availableValues: availableDamageRepairedAnswers,
            value: repairDTO.value.damageAlreadyRepaired
        },
        fNOLRepairEstimatesContentSection: {
            repairDTO: repairDTO,
            wizardDTO: wizardDTO,
            onValidate: onValidate,
            writeValue: writeValue,
            visible: showContentEstimateSection
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            ContentEstimateComponent
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={repairDTO}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onComponentValidate}
                onValueChange={writeValue}
            />
        </div>
    );
}

FNOLRepairEstimates.defaultProps = {
    showBuildingContent: false
};

export default FNOLRepairEstimates;
