/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable no-console */
import _ from 'lodash';
import React, { useCallback, useState, useContext } from 'react';
import { WizardPage, WizardPageTemplate, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { withRouter } from 'react-router-dom';
import { useValidation } from 'gw-portals-validation-react';
import { TranslatorContext } from '@jutro/locale';
import { FNOLService } from 'nn-capability-fnol';
import PartnerContext from '../../contexts/PartnerContext/PartnerContext';
import wizardConfig from '../../config/fnol-wizard-config.json5';
import { isAutoLOB, isPropertyLOB, isMotorLOB, isBikeLOB } from '../../utils/PolicyTypeUtil';
import { getProgressBasedOnWizardStep } from '../../utils/WizardStepToProgressUtil';
import useSnapshotHandler from '../../hooks/useSnapshotHandler';
import styles from './WizardFNOLLossDetailsPage.module.scss';
import metadata from './WizardFNOLLossDetailsPage.metadata.json5';
import messages from './WizardFNOLLossDetailsPage.messages';
import PleaseWait from '../../components/PleaseWait/PleaseWait';
import useUnmountHandler from '../../hooks/useUnmountHandler';
import LossDetailsVehicle from '../../components/lossDetailsComponents/LossDetailsVehicle/LossDetailsVehicle';
import LossDetailsProperty from '../../components/lossDetailsComponents/LossDetailsProperty/LossDetailsProperty';
import LossDetailsBike from '../../components/lossDetailsComponents/LossDetailsBike/LossDetailsBike';

function WizardFNOLLossDetailsPage(props) {
    const isComponentMounted = useUnmountHandler();
    const translator = useContext(TranslatorContext);
    const partnerContext = useContext(PartnerContext);
    const [isLoading, setIsLoading] = useState(false);

    const {
        history,
        wizardData,
        updateWizardData,
        currentStepIndex,
        changeNextSteps,
        steps
    } = props;

    const {
        onValidate,
        isComponentValid,
    } = useValidation('WizardFNOLLossDetailsPage');

    const [resetValuesToSnapshotIfNeeded, prepareSnapshot] = useSnapshotHandler(steps,
        currentStepIndex, updateWizardData, 'lossDetailsSnapshot', history);

    const writeValue = useCallback((value, path) => {
        const { currentDTOValue } = resetValuesToSnapshotIfNeeded(wizardData, true, setIsLoading);
        if (currentDTOValue !== undefined) {
            _.set(currentDTOValue.value, path, value);
            updateWizardData(currentDTOValue);
        }
    }, [wizardData, updateWizardData, resetValuesToSnapshotIfNeeded, setIsLoading]);

    const overrides = {
        pleaseWait: {
            value: isLoading
        },
        lossDetailsVehicle: {
            history: history,
            visible: isAutoLOB(wizardData.lob.value) || isMotorLOB(wizardData.lob.value),
            writeValue: writeValue,
            setIsLoading: setIsLoading,
            value: wizardData
        },
        lossDetailsProperty: {
            visible: isPropertyLOB(wizardData.lob.value),
            writeValue: writeValue,
            policyHolderRole: (wizardData.value.policyHolderRoleInhabit),
            value: wizardData
        },
        lossDetailsBike: {
            visible: isBikeLOB(wizardData.lob.value),
            writeValue: writeValue,
            value: wizardData
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            writeValue,
            onValidate
        },
        resolveComponentMap: {
            pleaseWait: PleaseWait,
            lossDetailsVehicle: LossDetailsVehicle,
            lossDetailsProperty: LossDetailsProperty,
            lossDetailsBike: LossDetailsBike
        }
    };

    const handleStepsAdjustments = useCallback(() => {
        const shouldRemoveRepairStep = wizardConfig.steps.length === steps.length
            && isPropertyLOB(wizardData.lob.value)
            && wizardData.value.propertyDamage !== undefined
            && wizardData.value.propertyDamage.find((damage) => damage.isSelected === true && ['parked_vehicle', 'domestic_animals', 'other', 'other_unknown_nodmg'].indexOf(damage.damagedProperty) !== -1) !== undefined;

        if (shouldRemoveRepairStep) {
            const newSteps = steps.slice(currentStepIndex + 2, steps.length);
            changeNextSteps(newSteps);
            return newSteps;
        }

        const shouldRestoreRepairStep = wizardConfig.steps.length !== steps.length
            && isPropertyLOB(wizardData.lob.value)
            && wizardData.value.propertyDamage !== undefined
            && wizardData.value.propertyDamage.find((damage) => damage.isSelected === true && ['parked_vehicle', 'domestic_animals', 'other', 'other_unknown_nodmg'].indexOf(damage.damagedProperty) !== -1) === undefined;

        if (shouldRestoreRepairStep) {
            const newSteps = wizardConfig.steps.slice(2);
            changeNextSteps(newSteps);
            return newSteps;
        }

        return steps;
    }, [wizardData, steps, changeNextSteps, currentStepIndex]);

    const handleOnNextClick = useCallback(() => {
        setIsLoading(true);

        const newSteps = handleStepsAdjustments();
        const wereStepsAltered = wizardConfig.steps.length !== newSteps.length;
        const nextStep = wereStepsAltered ? newSteps[0].path : newSteps[1].path;

        const valueToSend = wizardData.value;
        valueToSend.progress = getProgressBasedOnWizardStep(nextStep);
        return FNOLService.updateClaim(valueToSend, history, { partnerContext, translator }).then(
            (responseDTO) => {
                if (!responseDTO) return;
                console.log('saving claim response', responseDTO);
                wizardData.value = responseDTO;
                prepareSnapshot(responseDTO);
                return wizardData;
            }
        ).finally(() => {
            if (isComponentMounted) {
                setIsLoading(false);
            }
        });
    }, [handleStepsAdjustments, wizardData, history, partnerContext,
        translator, prepareSnapshot, isComponentMounted]);

    const generateHeader = () => {
        return (
            <div>
                <h1>
                    {translator(messages.wizardFnolLossDetailsTitle)}
                </h1>
                <h1>
                    {wizardData.value.claimNumber}
                </h1>
            </div>
        );
    };

    return (
        <WizardPage
            template={WizardPageTemplate}
            onNext={handleOnNextClick}
            disableNext={!isComponentValid && !isLoading}
            showCancel={false}
            renderContextComponent={generateHeader}
        >
            <ViewModelForm
                model={wizardData}
                uiProps={metadata.pageContent}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                overrideProps={overrides}
                onValueChange={writeValue}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

WizardFNOLLossDetailsPage.propTypes = wizardProps;
export default withRouter(WizardFNOLLossDetailsPage);
