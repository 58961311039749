import { defineMessages } from 'react-intl';

export default defineMessages({
    wizardFNOLPropertyRepairHeaderFirstText: {
        id: 'anonymous.fnol.wizard.pages.repair.components.property.Would you like to have the damage repaired through NN repair service or choose to take case of damage yourself?',
        defaultMessage: 'Would you like to have the damage repaired through NN repair service or choose to take case of damage yourself?'
    },
    wizardFNOLPropertyRepairHeaderSecondText: {
        id: 'anonymous.fnol.wizard.pages.repair.components.property.Your claims handler will assess and inform you if NN can repair the damage for you if you choose the NN repair services.',
        defaultMessage: 'Your claims handler will assess and inform you if NN can repair the damage for you if you choose the NN repair services.'
    },
    wizardFNOLPropertyRepairChoiceRecommendedFacility: {
        id: 'anonymous.fnol.wizard.pages.repair.components.property.repairChoice.I prefer NN will take care of it.',
        defaultMessage: 'I prefer NN will take care of it.'
    },
    wizardFNOLPropertyRepairChoiceOwnVendor: {
        id: "anonymous.fnol.wizard.pages.repair.components.property.repairChoice.I'll take care of it myself.",
        defaultMessage: "I'll take care of it myself."
    },
    wizardFNOLPropertyRepairChoiceOwnVendorNoRepairInKind: {
        id: 'anonymous.fnol.wizard.pages.repair.components.property.noRepairInKind.repairChoice.Please indicate below the cost of your loss',
        defaultMessage: 'Please indicate below the cost of your loss'
    },
    wizardFNOLPropertyRepairNoVendorChoice: {
        id: 'anonymous.fnol.wizard.pages.repair.components.property.repairChoice.Not yet decided.',
        defaultMessage: 'I have not yet decided.'
    },
    ownVendorSecondaryLabelHeader: {
        id: 'anonymous.repairChoice.property.ownVendor.secondaryLabel.Below are the advantages of having the repair carried out by a repairer from our network:',
        defaultMessage: 'Below are the advantages of having the repair carried out by a repairer from our network:'
    },
    ownVendorSecondaryLabelContractorClause: {
        id: 'anonymous.repairChoice.property.ownVendor.secondaryLabel.You do not have to find a contractor yourself',
        defaultMessage: 'You do not have to find a contractor yourself.'
    },
    ownVendorSecondaryLabelQuickRepairClause: {
        id: 'anonymous.repairChoice.property.ownVendor.secondaryLabel.Our repairer contacts you within 2 working days to make an appointment',
        defaultMessage: 'Our repairer contacts you within 2 working days to make an appointment.'
    },
    ownVendorSecondaryLabelQuotationsClause: {
        id: "anonymous.repairChoice.property.ownVendor.secondaryLabel.You don't have to ask for quotations(anymore) and compare prices",
        defaultMessage: "You don't have to ask for quotations(anymore) and compare prices."
    },
    ownVendorSecondaryLabelExemptionClause: {
        id: 'anonymous.repairChoice.property.ownVendor.secondaryLabel.The exemption is halved for this repair',
        defaultMessage: 'The excess is reduced to 125 euros, but in Glass Break, if several windows are damaged, we charge an excess of 250 euros per additional window.'
    },
    ownVendorSecondaryLabelWeArrangeEverythingClause: {
        id: 'anonymous.repairChoice.property.ownVendor.secondaryLabel.You do not have to advance anything yourself. We arrange everything directly with the repairer.',
        defaultMessage: 'You do not have to advance anything yourself. We arrange everything directly with the repairer.'
    },
    ownVendorSecondaryLabelQualityClause: {
        id: 'anonymous.repairChoice.property.ownVendor.secondaryLabel.We guarantee the quality of the repairs.',
        defaultMessage: 'We guarantee the quality of the repairs.'
    },
    ownVendorSecondaryLabelWarrantyClause: {
        id: 'anonymous.repairChoice.property.ownVendor.secondaryLabel.You receive a 1-year warranty on the repairs carried out.',
        defaultMessage: 'You receive a 1-year warranty on the repairs carried out.'
    }
});
