import _defineProperty from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _extends from "@babel/runtime-corejs3/helpers/extends";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DATA_TYPE_OBJECT, dataTypeShapeWithDateTime, intlMessageShape, dateValueShape, deprecated } from '@jutro/prop-types';
import { LocaleContext, IntlContext } from '@jutro/locale';
import { FieldComponent } from '../FieldComponent/FieldComponent';
import { DateFieldInternalDEPRECATED } from './DateFieldInternal_DEPRECATED';
import { FieldIcon } from '../FieldComponent/FieldIcon';
import { DateFieldInternal } from './DateFieldInternal';
import { minDefaultYear, maxDefaultYear } from './DateField.commons';
export var dateFormatTypes = ['vshort', 'short', 'long', 'abbreviated', 'full'];
export var popperPlacementPositions = ['auto', 'auto-end', 'bottom', 'bottom-end', 'bottom-start', 'left', 'left-end', 'left-start', 'right', 'right-end', 'right-start', 'top', 'top-end', 'top-start'];

var dateFieldDefaultProps = _objectSpread({}, FieldComponent.defaultProps, {
  icon: 'mi-calendar-today',
  iconPosition: 'right',
  dataType: DATA_TYPE_OBJECT,
  format: 'short',
  minDate: new Date(minDefaultYear, 0),
  maxDate: new Date(maxDefaultYear, 0),
  autoComplete: false,
  showTime: false,
  showCalendar: true
});

export var dateFieldPropTypes = _objectSpread({}, FieldComponent.propTypes, {
  todayButtonText: intlMessageShape,
  maxDate: dateValueShape.isRequired,
  minDate: dateValueShape.isRequired,
  isClearable: PropTypes.bool,
  value: dateValueShape,
  dataType: dataTypeShapeWithDateTime.isRequired,
  autoComplete: PropTypes.bool,
  popperPlacement: PropTypes.oneOf(popperPlacementPositions),
  format: PropTypes.oneOf(dateFormatTypes).isRequired,
  showTime: deprecated(PropTypes.bool, '6.0.0', 'Use DateTimeField instead'),
  theme: PropTypes.shape({})
}, FieldIcon.propTypes, {
  isInitiallyOpen: PropTypes.bool,
  showCalendar: PropTypes.bool
});
export var DateField = function DateField(props) {
  var intl = useContext(IntlContext);

  var _useContext = useContext(LocaleContext),
      dateLocale = _useContext.dateLocale;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      updateFlag = _useState2[0],
      triggerUpdate = _useState2[1];

  useEffect(function () {
    triggerUpdate(!updateFlag);
  }, [intl]);

  if (props.showTime) {
    return React.createElement(DateFieldInternalDEPRECATED, _extends({}, props, {
      intl: intl,
      updateFlag: updateFlag,
      locale: dateLocale
    }));
  }

  return React.createElement(DateFieldInternal, _extends({}, props, {
    intl: intl,
    updateFlag: updateFlag,
    locale: dateLocale
  }));
};
DateField.displayName = 'DateField';
DateField.propTypes = dateFieldPropTypes;
DateField.defaultProps = dateFieldDefaultProps;
DateField.__docgenInfo = {
  componentName: "DateField",
  packageName: "@jutro/components",
  description: "Allows the user to either type or select a date. Displays them according to locale settings.\nValid format for manual input is ISO YYYY-MM-DD or MM/DD/YYYY. The time part (for the date and time\nfield) should be appended to the date and follow ISO standard. For example: 2020-05-03T:10:30.\nNote that time values are assumed to be in client time zone. Unless time zone is explicitly\nprovided through manual input (as per ISO format). Date only input treats dates as UTC dates.",
  displayName: "DateField",
  methods: [],
  actualName: "DateField",
  metadataType: "field",
  props: {
    todayButtonText: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Display today button"
    },
    maxDate: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }, {
          name: "instanceOf",
          value: "Date"
        }, {
          name: "shape",
          value: {
            year: {
              name: "number",
              required: true
            },
            month: {
              name: "number",
              required: true
            },
            day: {
              name: "number",
              required: true
            },
            hour: {
              name: "number",
              required: false
            },
            minute: {
              name: "number",
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Max date",
      defaultValue: {
        value: "new Date(maxDefaultYear, 0)",
        computed: false
      }
    },
    minDate: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }, {
          name: "instanceOf",
          value: "Date"
        }, {
          name: "shape",
          value: {
            year: {
              name: "number",
              required: true
            },
            month: {
              name: "number",
              required: true
            },
            day: {
              name: "number",
              required: true
            },
            hour: {
              name: "number",
              required: false
            },
            minute: {
              name: "number",
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Min date",
      defaultValue: {
        value: "new Date(minDefaultYear, 0)",
        computed: false
      }
    },
    isClearable: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, field can be cleaned"
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }, {
          name: "instanceOf",
          value: "Date"
        }, {
          name: "shape",
          value: {
            year: {
              name: "number",
              required: true
            },
            month: {
              name: "number",
              required: true
            },
            day: {
              name: "number",
              required: true
            },
            hour: {
              name: "number",
              required: false
            },
            minute: {
              name: "number",
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Date to display"
    },
    dataType: {
      type: {
        name: "enum",
        value: [{
          value: "'object'",
          computed: false
        }, {
          value: "'string'",
          computed: false
        }, {
          value: "'date-time'",
          computed: false
        }]
      },
      required: false,
      description: "Format of the value",
      defaultValue: {
        value: "'object'",
        computed: false
      }
    },
    autoComplete: {
      type: {
        name: "bool"
      },
      required: false,
      description: "HTML5 native autoComplete support",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    popperPlacement: {
      type: {
        name: "enum",
        value: [{
          value: "'auto'",
          computed: false
        }, {
          value: "'auto-end'",
          computed: false
        }, {
          value: "'bottom'",
          computed: false
        }, {
          value: "'bottom-end'",
          computed: false
        }, {
          value: "'bottom-start'",
          computed: false
        }, {
          value: "'left'",
          computed: false
        }, {
          value: "'left-end'",
          computed: false
        }, {
          value: "'left-start'",
          computed: false
        }, {
          value: "'right'",
          computed: false
        }, {
          value: "'right-end'",
          computed: false
        }, {
          value: "'right-start'",
          computed: false
        }, {
          value: "'top'",
          computed: false
        }, {
          value: "'top-end'",
          computed: false
        }, {
          value: "'top-start'",
          computed: false
        }]
      },
      required: false,
      description: "Popper placement option"
    },
    format: {
      type: {
        name: "enum",
        value: [{
          value: "'vshort'",
          computed: false
        }, {
          value: "'short'",
          computed: false
        }, {
          value: "'long'",
          computed: false
        }, {
          value: "'abbreviated'",
          computed: false
        }, {
          value: "'full'",
          computed: false
        }]
      },
      required: false,
      description: "The readonly date format: 'short', 'long', 'abbreviated' or 'full'",
      defaultValue: {
        value: "'short'",
        computed: false
      }
    },
    showTime: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Include a time selection element in the date picker\n@deprecated",
      defaultValue: {
        value: "false",
        computed: false
      },
      deprecationInfo: {
        version: "6.0.0",
        mapTo: null
      }
    },
    theme: {
      type: {
        name: "shape",
        value: {}
      },
      required: false,
      description: "Theme config to apply"
    },
    isInitiallyOpen: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Should dropdown be initially opened"
    },
    showCalendar: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Should display the calendar pop-over",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    icon: {
      defaultValue: {
        value: "'mi-calendar-today'",
        computed: false
      },
      required: false
    },
    iconPosition: {
      defaultValue: {
        value: "'right'",
        computed: false
      },
      required: false
    }
  },
  composes: ["../FieldComponent/FieldComponent", "../FieldComponent/FieldIcon"]
};