import _defineProperty from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from "./Header.module.css";
var headerPropTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  sticky: PropTypes.bool,
  children: PropTypes.node,
  fluid: PropTypes.bool
};
export var Header = function Header(props) {
  var children = props.children,
      className = props.className,
      containerClassName = props.containerClassName,
      sticky = props.sticky,
      fluid = props.fluid,
      dangerouslySetInnerHTML = props.dangerouslySetInnerHTML,
      rest = _objectWithoutProperties(props, ["children", "className", "containerClassName", "sticky", "fluid", "dangerouslySetInnerHTML"]);

  var classes = cx(styles.header, _defineProperty({}, styles.positionSticky, sticky), className);
  var containerClasses = cx(styles.headerContainer, styles.headerContent, containerClassName, _defineProperty({}, styles.fluid, fluid));
  return React.createElement("header", _extends({
    className: classes
  }, rest), React.createElement("div", {
    className: containerClasses
  }, children));
};
Header.propTypes = headerPropTypes;
Header.__docgenInfo = {
  componentName: "Header",
  packageName: "@jutro/components",
  description: "Header",
  displayName: "Header",
  methods: [],
  actualName: "Header",
  metadataType: "container",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for component."
    },
    containerClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class name for children container."
    },
    sticky: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Sticky position of header."
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "Children of header like buttons, icons, search boxes. The immediate children\nare laid out in a flexbox with space-between and it is up to individual children\nto grow/shrink as appropriate."
    },
    fluid: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Span the entire width."
    }
  }
};