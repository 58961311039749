import _defineProperty from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

var _eventTopicMap;

import { JUTRO_TOPICS } from '@jutro/events';
export var HttpEventTypes;

(function (HttpEventTypes) {
  HttpEventTypes["request"] = "request";
  HttpEventTypes["response"] = "response";
  HttpEventTypes["error"] = "error";
  HttpEventTypes["exception"] = "exception";
})(HttpEventTypes || (HttpEventTypes = {}));

export var eventTopicMap = (_eventTopicMap = {}, _defineProperty(_eventTopicMap, HttpEventTypes.request, JUTRO_TOPICS.FETCH_REQUEST), _defineProperty(_eventTopicMap, HttpEventTypes.response, JUTRO_TOPICS.FETCH_RESPONSE), _defineProperty(_eventTopicMap, HttpEventTypes.error, JUTRO_TOPICS.FETCH_ERROR), _defineProperty(_eventTopicMap, HttpEventTypes.exception, JUTRO_TOPICS.FETCH_ERROR), _eventTopicMap);
export var JSON_CONTENT_TYPE = 'application/json';
export var BLOB_CONTENT_TYPE = 'application/octet-stream';
export var CONTENT_TYPE = 'Content-Type';