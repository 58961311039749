import _defineProperty2 from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty2(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _forEachInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/for-each";

var _dec, _class, _temp;

import { ServiceManager, service } from '@jutro/services';
import { log } from '@jutro/logger';
import { getConfigValue } from '@jutro/config';
import localeCodes from 'countries-list';
import customLanguagesList from './customLanguages.json';
import LocaleSettingsInterface from './LocaleSettingsInterface';
import { formatShortDate as _formatShortDate, formatLongDate as _formatLongDate, formatCurrency as _formatCurrency, formatPhoneNumber as _formatPhoneNumber } from './valueFormatter';
import { getBrowserLocale, getCountryCodeFromLocale, getLanguageFromLocale } from './locale';
export var LOCALE_SERVICE_ID = 'locale';
var countriesList = localeCodes.countries,
    languagesList = localeCodes.languagesAll;

var languagesListWithPseudoLocales = _objectSpread({}, customLanguagesList, {}, languagesList);

var localeSettings;
var localeConfiguration;

var getLocaleSettings = function getLocaleSettings() {
  if (!localeSettings) {
    localeSettings = ServiceManager.getServiceByContract(LocaleSettingsInterface);

    if (!localeSettings) {
      log.warning('No implementation of LocaleSettingsInterface found.');
    }
  }

  return localeSettings;
};

var getLocaleConfig = function getLocaleConfig() {
  if (!localeConfiguration) {
    localeConfiguration = getConfigValue('localeSettings');
  }

  return localeConfiguration;
};

var getStoredLocale = function getStoredLocale() {
  return localStorage.getItem('selectedLocale');
};

var getStoredLanguage = function getStoredLanguage() {
  return localStorage.getItem('selectedLanguage');
};

var saveLocale = function saveLocale(locale) {
  localStorage.setItem('selectedLocale', locale);
};

var saveLanguage = function saveLanguage(language) {
  localStorage.setItem('selectedLanguage', language);
};

export var getNativeLocaleLanguage = function getNativeLocaleLanguage(locale) {
  var languageCode = getLanguageFromLocale(locale);
  var countryCode = getCountryCodeFromLocale(locale);
  var languageMapping = languageCode !== undefined ? languagesList[languageCode] : undefined;
  var countryMapping = countryCode !== undefined ? countriesList[countryCode] : undefined;

  if (languageMapping && countryMapping) {
    return "".concat(languageMapping.native, " (").concat(countryMapping.native, ")");
  }

  if (languageCode !== undefined) {
    if (languagesListWithPseudoLocales[locale]) {
      return languagesListWithPseudoLocales[locale].native;
    }
  }

  return undefined;
};
export var getAllCountriesUsingLanguage = function getAllCountriesUsingLanguage(locale) {
  var _context;

  var language = getLanguageFromLocale(locale);
  var countries = [];

  _forEachInstanceProperty(_context = _Object$keys(countriesList)).call(_context, function (country) {
    var _context2;

    var countryKeyIndex = country;

    if (_includesInstanceProperty(_context2 = countriesList[countryKeyIndex].languages).call(_context2, locale)) {
      countries.push({
        country: country,
        native: countriesList[countryKeyIndex].native,
        locale: "".concat(language, "-").concat(country),
        language: language,
        english: countriesList[countryKeyIndex].name
      });
    }
  });

  return countries;
};
export var getCountryNameFromLocale = function getCountryNameFromLocale(locale) {
  var languageCode = getLanguageFromLocale(locale);

  if (languageCode) {
    if (languagesListWithPseudoLocales[languageCode]) {
      return languagesListWithPseudoLocales[languageCode].native;
    }
  }

  var countryCode = getCountryCodeFromLocale(locale);
  var languageMapping = languageCode !== undefined ? languagesList[languageCode] : undefined;
  var countryMapping = countryCode !== undefined ? countriesList[countryCode] : undefined;

  if (languageMapping && countryMapping) {
    return "".concat(countryMapping.native);
  }

  return undefined;
};

var findAvailableLocale = function findAvailableLocale() {
  var browserLocale = getBrowserLocale();

  var availableLocales = _getAvailableLocales();

  if (availableLocales && availableLocales.length && _includesInstanceProperty(availableLocales).call(availableLocales, browserLocale)) {
    return browserLocale;
  }

  var preferredLocale = getPreferredLocale();

  if (preferredLocale) {
    return preferredLocale;
  }

  return browserLocale;
};

var findAvailableLanguage = function findAvailableLanguage() {
  var browserLocale = getBrowserLocale();

  var availableLanguages = _getAvailableLanguages();

  if (availableLanguages && availableLanguages.length && _includesInstanceProperty(availableLanguages).call(availableLanguages, browserLocale)) {
    return browserLocale;
  }

  var preferredLanguage = getPreferredLanguage();

  if (preferredLanguage) {
    return preferredLanguage;
  }

  return browserLocale;
};

var _getAvailableLanguages = function _getAvailableLanguages() {
  var settings = getLocaleSettings();
  var localeConfig = getLocaleConfig();

  if (settings) {
    return settings.getAvailableLanguages();
  }

  if (localeConfig && localeConfig.availableLanguages) {
    return localeConfig.availableLanguages;
  }

  return [];
};

var _getAvailableLocales = function _getAvailableLocales() {
  var settings = getLocaleSettings();
  var localeConfig = getLocaleConfig();

  if (settings) {
    return settings.getAvailableLocales();
  }

  if (localeConfig && localeConfig.availableLocales) {
    return localeConfig.availableLocales;
  }

  log.warning('No locale configuration or settings provided.');
  return [];
};

var getPreferredLanguage = function getPreferredLanguage() {
  var settings = getLocaleSettings();
  var localeConfig = getLocaleConfig();

  if (settings) {
    return settings.getPreferredLanguage();
  }

  if (localeConfig && localeConfig.preferredLanguage) {
    return localeConfig.preferredLanguage;
  }

  log.warning('No locale configuration or settings provided.');
  return null;
};

var getPreferredLocale = function getPreferredLocale() {
  var settings = getLocaleSettings();
  var localeConfig = getLocaleConfig();

  if (settings) {
    return settings.getPreferredLocale();
  }

  if (localeConfig && localeConfig.preferredLocale) {
    return localeConfig.preferredLocale;
  }

  log.warning('No locale configuration or settings provided.');
  return null;
};

export var getDefaultCountryCode = function getDefaultCountryCode() {
  var settings = getLocaleSettings();
  var localeConfig = getLocaleConfig();

  if (settings) {
    return settings.getDefaultCountryCode();
  }

  if (localeConfig && localeConfig.defaultCountryCode) {
    return localeConfig.defaultCountryCode;
  }

  log.warning('No locale configuration or settings provided.');
  return null;
};

var getDefaultPhoneCountry = function getDefaultPhoneCountry() {
  var settings = getLocaleSettings();
  var localeConfig = getLocaleConfig();

  if (settings) {
    return settings.getDefaultPhoneCountry();
  }

  if (localeConfig && localeConfig.defaultPhoneCountry) {
    return localeConfig.defaultPhoneCountry;
  }

  log.warning('No locale configuration or settings provided.');
  return null;
};

export var LocaleService = (_dec = service(LOCALE_SERVICE_ID), _dec(_class = (_temp = function () {
  function LocaleService() {
    _classCallCheck(this, LocaleService);

    _defineProperty(this, "localeChangeListeners", void 0);

    _defineProperty(this, "languageChangeListeners", void 0);

    _defineProperty(this, "currentLocale", void 0);

    _defineProperty(this, "currentLanguage", void 0);

    this.localeChangeListeners = [];
    this.languageChangeListeners = [];
    this.currentLocale = getStoredLocale();
    this.currentLanguage = getStoredLanguage();
  }

  _createClass(LocaleService, [{
    key: "getAvailableLocales",
    value: function getAvailableLocales() {
      return _getAvailableLocales();
    }
  }, {
    key: "getAvailableLanguages",
    value: function getAvailableLanguages() {
      return _getAvailableLanguages();
    }
  }, {
    key: "getCountry",
    value: function getCountry() {
      return this.getCurrentCountry() || getDefaultCountryCode();
    }
  }, {
    key: "getCurrentCountry",
    value: function getCurrentCountry() {
      return getCountryCodeFromLocale(this.locale);
    }
  }, {
    key: "getCurrentLocale",
    value: function getCurrentLocale() {
      if (!this.currentLocale) {
        this.setCurrentLocale(findAvailableLocale());
      }

      return this.currentLocale;
    }
  }, {
    key: "getCurrentLanguage",
    value: function getCurrentLanguage() {
      if (!this.currentLanguage) {
        this.setCurrentLanguage(findAvailableLanguage());
      }

      return this.currentLanguage;
    }
  }, {
    key: "getStoredLocale",
    value: function getStoredLocale() {
      return localStorage.getItem('selectedLocale');
    }
  }, {
    key: "getStoredLanguage",
    value: function getStoredLanguage() {
      return localStorage.getItem('selectedLanguage');
    }
  }, {
    key: "setCurrentLocale",
    value: function setCurrentLocale(newLocale) {
      var skipLocalStoragePersistence = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var _context3;

      this.currentLocale = newLocale;

      if (!skipLocalStoragePersistence) {
        saveLocale(newLocale);
      }

      _forEachInstanceProperty(_context3 = this.localeChangeListeners).call(_context3, function (listener) {
        return listener(newLocale);
      });
    }
  }, {
    key: "setCurrentLanguage",
    value: function setCurrentLanguage(newLanguage) {
      var skipLocalStoragePersistence = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var _context4;

      this.currentLanguage = newLanguage;

      if (!skipLocalStoragePersistence) {
        saveLanguage(newLanguage);
      }

      _forEachInstanceProperty(_context4 = this.languageChangeListeners).call(_context4, function (listener) {
        return listener(newLanguage);
      });
    }
  }, {
    key: "onLocaleChange",
    value: function onLocaleChange(callback) {
      this.localeChangeListeners.push(callback);
    }
  }, {
    key: "onLanguageChange",
    value: function onLanguageChange(callback) {
      this.languageChangeListeners.push(callback);
    }
  }, {
    key: "getConfiguredPhoneRegion",
    value: function getConfiguredPhoneRegion() {
      return getDefaultPhoneCountry();
    }
  }, {
    key: "formatShortDate",
    value: function formatShortDate(date) {
      return _formatShortDate(date, this.locale);
    }
  }, {
    key: "formatLongDate",
    value: function formatLongDate(date) {
      return _formatLongDate(date, this.locale);
    }
  }, {
    key: "formatCurrency",
    value: function formatCurrency(amount, currency) {
      return _formatCurrency(amount, currency, this.locale);
    }
  }, {
    key: "formatPhoneNumber",
    value: function formatPhoneNumber(phoneNumber) {
      return _formatPhoneNumber(phoneNumber, this.locale);
    }
  }, {
    key: "settings",
    get: function get() {
      return getLocaleSettings();
    }
  }, {
    key: "locale",
    get: function get() {
      return this.getCurrentLocale();
    },
    set: function set(newLocale) {
      this.setCurrentLocale(newLocale);
    }
  }, {
    key: "language",
    get: function get() {
      return this.getCurrentLanguage();
    },
    set: function set(newLanguage) {
      this.setCurrentLanguage(newLanguage);
    }
  }]);

  return LocaleService;
}(), _temp)) || _class);
export default ServiceManager.getService(LOCALE_SERVICE_ID);