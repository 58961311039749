import _defineProperty from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _valuesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/values";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import get from "lodash/get";
import { routesShape, contextSwitcherShape } from '@jutro/prop-types';
import cx from 'classnames';
import { NavBarLink } from './NavBarLink';
import styles from "./NavigationContent.module.css";
import { ContextSwitcher } from '../ContextSwitcher/ContextSwitcher';
export var NavigationContent = function NavigationContent(_ref) {
  var routes = _ref.routes,
      contextSwitcher = _ref.contextSwitcher,
      nestedRoutesComponent = _ref.nestedRoutesComponent,
      alignment = _ref.alignment,
      className = _ref.className,
      contextSwitcherClassName = _ref.contextSwitcherClassName,
      navBarRef = _ref.navBarRef;

  var _contextSwitcher$valu;

  var isTop = alignment === 'top';
  var classes = cx(styles.navigationContent, className);
  var contextSwitcherClasses = cx(styles.contextSwitcherContainer, contextSwitcherClassName);
  var items = useMemo(function () {
    var _context;

    var index = -1;
    return _filterInstanceProperty(_context = _mapInstanceProperty(routes).call(routes, function (_ref2) {
      var title = _ref2.title,
          _ref2$navLink = _ref2.navLink,
          navLink = _ref2$navLink === void 0 ? {} : _ref2$navLink,
          path = _ref2.path,
          href = _ref2.href,
          target = _ref2.target,
          _ref2$showOnNavBar = _ref2.showOnNavBar,
          showOnNavBar = _ref2$showOnNavBar === void 0 ? true : _ref2$showOnNavBar,
          exact = _ref2.exact,
          navRoutes = _ref2.routes,
          nestedRoutesComponentForNavItem = _ref2.nestedRoutesComponent,
          id = _ref2.id;

      if (!showOnNavBar) {
        return null;
      }

      index += 1;

      var navItemProps = _objectSpread({
        header: navLink.text || title,
        icon: navLink.icon,
        to: path,
        href: href,
        target: target,
        exact: exact,
        className: navLink.className,
        activeClassName: navLink.activeClassName,
        alignment: alignment,
        id: navLink.id || id || "navLink_".concat(index),
        routes: navRoutes,
        role: 'menuitem',
        notifications: navLink.notifications
      }, navLink.componentProps);

      var routesComponent = get(navRoutes, 'length', 0) && (nestedRoutesComponentForNavItem || nestedRoutesComponent);
      var NavComponent = navLink.component || routesComponent || NavBarLink;
      return {
        key: path + title + index,
        NavComponent: NavComponent,
        navItemProps: navItemProps
      };
    })).call(_context, function (item) {
      return item;
    });
  }, [routes, alignment, nestedRoutesComponent]);
  return React.createElement(React.Fragment, null, (contextSwitcher === null || contextSwitcher === void 0 ? void 0 : (_contextSwitcher$valu = _valuesInstanceProperty(contextSwitcher)) === null || _contextSwitcher$valu === void 0 ? void 0 : _contextSwitcher$valu.length) > 0 && React.createElement("div", {
    className: contextSwitcherClasses
  }, React.createElement(ContextSwitcher, contextSwitcher)), React.createElement("ul", {
    className: classes,
    role: isTop ? 'menubar' : 'menu',
    ref: navBarRef
  }, _mapInstanceProperty(items).call(items, function (_ref3) {
    var key = _ref3.key,
        NavComponent = _ref3.NavComponent,
        navItemProps = _ref3.navItemProps;
    return React.createElement("li", {
      className: cx(_defineProperty({}, styles.topNavigationItem, isTop)),
      role: "none",
      key: key
    }, React.createElement(NavComponent, navItemProps));
  })));
};
NavigationContent.propTypes = {
  routes: routesShape,
  contextSwitcher: contextSwitcherShape,
  alignment: PropTypes.oneOf(['top', 'left']),
  className: PropTypes.string,
  navBarRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })
};
NavigationContent.__docgenInfo = {
  componentName: "NavigationContent",
  packageName: "@jutro/router",
  description: "",
  displayName: "NavigationContent",
  methods: [],
  actualName: "NavigationContent",
  props: {
    routes: {
      type: {
        name: "arrayOf",
        value: {
          name: "custom",
          raw: "routeShape"
        }
      },
      required: false,
      description: "Routing metadata object"
    },
    contextSwitcher: {
      type: {
        name: "shape",
        value: {
          defaultLabel: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Default button label when no context is active",
            required: false
          },
          values: {
            name: "arrayOf",
            value: {
              name: "custom",
              raw: "contextShape.isRequired"
            },
            description: "Array of values for the contexts",
            required: true
          }
        }
      },
      required: false,
      description: "Context switcher object"
    },
    alignment: {
      type: {
        name: "enum",
        value: [{
          value: "'top'",
          computed: false
        }, {
          value: "'left'",
          computed: false
        }]
      },
      required: false,
      description: "Navigation alignment"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for component"
    },
    navBarRef: {
      type: {
        name: "shape",
        value: {
          current: {
            name: "instanceOf",
            value: "Element",
            required: false
          }
        }
      },
      required: false,
      description: "Reference to the navigation bar HTML element"
    }
  }
};