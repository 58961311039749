import { defineMessages } from 'react-intl';

export default defineMessages({
    contentEstimateTitle: {
        id: 'anonymous.fnol.repair.contentEstimates.Content',
        defaultMessage: 'Content'
    },
    detailedEstimatePresentRadioButton: {
        id: 'anonymous.fnol.repair.contentEstimates.Do you have a detailed estimate?',
        defaultMessage: 'Do you have a detailed estimate?'
    },
    provideGrossAmount: {
        id: 'anonymous.fnol.repair.contentEstimates.Please provide the gross amount',
        defaultMessage: 'Please provide the gross amount'
    },
    addContentItem: {
        id: 'anonymous.fnol.repair.contentEstimates.Add content item',
        defaultMessage: 'Add content item'
    },
    removeContentItems: {
        id: 'anonymous.fnol.repair.contentEstimates.Remove selected items',
        defaultMessage: 'Remove selected items'
    },
    emptyTableLabel: {
        id: 'anonymous.fnol.repair.contentEstimates.The table is empty...',
        defaultMessage: 'The table is empty...'
    },
    contentItemCategoryLabel: {
        id: 'anonymous.fnol.repair.contentEstimates.Category',
        defaultMessage: 'Category'
    },
    contentItemNameLabel: {
        id: 'anonymous.fnol.repair.contentEstimates.Item',
        defaultMessage: 'Item'
    },
    contentItemDescriptionLabel: {
        id: 'anonymous.fnol.repair.contentEstimates.Specifications (Brand/Type/Model)',
        defaultMessage: 'Specifications (Brand/Type/Model)'
    },
    contentItemQuantityLabel: {
        id: 'anonymous.fnol.repair.contentEstimates.Quantity',
        defaultMessage: 'Quantity'
    },
    contentItemOrigPurchaseValueLabel: {
        id: 'anonymous.fnol.repair.contentEstimates.Original Purchase Value',
        defaultMessage: 'Original Purchase Value'
    },
    contentItemOrigPurchaseDateLabel: {
        id: 'anonymous.fnol.repair.contentEstimates.Purchase Date',
        defaultMessage: 'Purchase Date'
    },
    contentItemOrigCurrentValueLabel: {
        id: 'anonymous.fnol.repair.contentEstimates.Replacement/Current Value',
        defaultMessage: 'Replacement/Current Value'
    },
    thisIsRequiredField: {
        id: 'anonymous.fnol.repair.contentEstimates.This is a required field',
        defaultMessage: 'This is a required field'
    }
});
