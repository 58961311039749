import _defineProperty from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _extends from "@babel/runtime-corejs3/helpers/extends";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _someInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/some";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useBreakpoint } from '@jutro/layout';
import styles from "./PhaseProgressBar.module.css";
import { PhaseProgress, phaseProgressPropTypes } from './PhaseProgress';
import { PhaseProgressLabel } from './PhaseProgressLabel';
var phaseProgressBarPropTypes = {
  phases: PropTypes.arrayOf(PropTypes.shape(_objectSpread({}, phaseProgressPropTypes)).isRequired).isRequired,
  className: PropTypes.string
};
export var PhaseProgressBar = function PhaseProgressBar(_ref) {
  var phases = _ref.phases,
      className = _ref.className;

  var _useBreakpoint = useBreakpoint(),
      breakpoint = _useBreakpoint.breakpoint;

  var isPhone = breakpoint === 'phone';

  var renderPhaseLabel = function renderPhaseLabel() {
    var _context2;

    if (!isPhone) return null;

    var activePhase = _findInstanceProperty(phases).call(phases, function (phase) {
      var _context;

      return _someInstanceProperty(_context = phase.steps).call(_context, function (step) {
        return step.active;
      });
    });

    if (!activePhase) return null;

    var activeStep = _findInstanceProperty(_context2 = activePhase.steps).call(_context2, function (step) {
      return step.active;
    });

    var title = activePhase.title,
        icon = activePhase.icon,
        isError = activePhase.isError;
    var stepTitle = activeStep.title;
    return React.createElement(PhaseProgressLabel, {
      title: title,
      stepTitle: stepTitle,
      icon: icon,
      isError: isError,
      active: true
    });
  };

  var classes = cx(styles.phaseProgressBar, className);
  return React.createElement("nav", {
    className: classes
  }, React.createElement("div", {
    className: styles.phases
  }, _mapInstanceProperty(phases).call(phases, function (phaseProps, index) {
    var id = "".concat(phaseProps.title).concat(index);
    return React.createElement(PhaseProgress, _extends({}, phaseProps, {
      id: id,
      key: id,
      showLabel: !isPhone
    }));
  })), renderPhaseLabel());
};
PhaseProgressBar.propTypes = phaseProgressBarPropTypes;
PhaseProgressBar.__docgenInfo = {
  componentName: "PhaseProgressBar",
  packageName: "@jutro/components",
  description: "PhaseProgressBar",
  displayName: "PhaseProgressBar",
  methods: [],
  actualName: "PhaseProgressBar",
  props: {
    phases: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {}
        }
      },
      required: true,
      description: "Phases in progress indicator"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Class to override progress bar styles"
    }
  }
};