import _defineProperty from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { memo, useRef, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from "./Icon.module.css";
import { getIconClasses } from '../../iconUtils.js';
var iconPropTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  isFixedWidth: PropTypes.bool,
  tag: PropTypes.string,
  _rest: PropTypes.any
};

var IconInternal = function IconInternal(_ref) {
  var icon = _ref.icon,
      isFixedWidth = _ref.isFixedWidth,
      className = _ref.className,
      Tag = _ref.tag,
      rest = _objectWithoutProperties(_ref, ["icon", "isFixedWidth", "className", "tag"]);

  var iconRef = useRef();

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      emptyIcon = _useState2[0],
      setEmptyIcon = _useState2[1];

  useLayoutEffect(function () {
    var isEmptyIcon = !icon || !iconRef.current || window.getComputedStyle(iconRef.current, ':before').content === 'none';
    setEmptyIcon(isEmptyIcon);
  }, [icon]);

  if (!icon) {
    return null;
  }

  var classes = cx(getIconClasses(icon, Boolean(isFixedWidth)), _defineProperty({}, styles.empty, emptyIcon), className);
  return React.createElement(Tag, _extends({
    ref: iconRef,
    className: classes
  }, rest));
};

IconInternal.propTypes = iconPropTypes;
IconInternal.defaultProps = {
  tag: 'i',
  isFixedWidth: false
};
IconInternal.displayName = 'Icon';
export var Icon = memo(IconInternal);
IconInternal.__docgenInfo = {
  componentName: "Icon",
  packageName: "@jutro/components",
  description: "",
  displayName: "Icon",
  methods: [],
  actualName: "IconInternal",
  metadataType: "element",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    },
    icon: {
      type: {
        name: "string"
      },
      required: true,
      description: "Material Icon name (mi-*) to be used"
    },
    isFixedWidth: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Flag for fixed width font awesome icons",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    tag: {
      type: {
        name: "string"
      },
      required: false,
      description: "Tag used to render icon",
      defaultValue: {
        value: "'i'",
        computed: false
      }
    },
    _rest: {
      type: {
        name: "any"
      },
      required: false,
      description: "Set of custom props, specific to a particular tag being used"
    }
  }
};