import _defineProperty from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import { SET_FILTER_VALUE, SET_PAGE, SET_PAGE_SIZE, SET_SORTED, SET_COLUMNS, SET_EDITED_ROW } from './types';
export var reducer = function reducer(state, action) {
  var type = action.type;

  switch (type) {
    case SET_FILTER_VALUE:
      {
        var newState = _objectSpread({}, state, {
          filterValue: action.filterValue
        });

        if (action.resetPage) {
          newState.page = 0;
        }

        return newState;
      }

    case SET_PAGE:
      {
        return _objectSpread({}, state, {
          page: action.page
        });
      }

    case SET_PAGE_SIZE:
      {
        var _newState = _objectSpread({}, state, {
          pageSize: action.pageSize
        });

        if (action.resetPage) {
          _newState.page = 0;
        }

        return _newState;
      }

    case SET_SORTED:
      {
        var sorted = action.sorted;

        var _newState2 = _objectSpread({}, state, {
          sorted: sorted === null || sorted === void 0 ? void 0 : _mapInstanceProperty(sorted).call(sorted, function (method) {
            return _objectSpread({}, method);
          })
        });

        if (action.resetPage) {
          _newState2.page = 0;
        }

        return _newState2;
      }

    case SET_COLUMNS:
      {
        return _objectSpread({}, state, {
          columns: action.columns
        });
      }

    case SET_EDITED_ROW:
      {
        return _objectSpread({}, state, {
          editedRow: action.rowId
        });
      }

    default:
      return state;
  }
};