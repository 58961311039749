import _toConsumableArray from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/toConsumableArray";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _Object$getOwnPropertyNames from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-names";
import _forEachInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/for-each";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _sortInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/sort";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _ from 'lodash';
import { sha256 } from 'js-sha256';
import { log } from '@jutro/logger';
import ImplDescriptor from './ImplDescriptor';

var Contract = function () {
  function Contract(specification) {
    var _this = this;

    _classCallCheck(this, Contract);

    for (var _len = arguments.length, extended = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      extended[_key - 1] = arguments[_key];
    }

    this.spec = _.merge.apply(_, _toConsumableArray(_mapInstanceProperty(extended).call(extended, function (c) {
      return c.spec;
    })).concat([specification]));
    this.hashFunc = _.memoize(function () {
      var _context;

      var keyTar = _sortInstanceProperty(_context = _Object$keys(_this.spec)).call(_context).join('');

      return sha256(keyTar);
    });
  }

  _createClass(Contract, [{
    key: "implementedBy",
    value: function implementedBy(implementor) {
      var _this2 = this;

      var _context2;

      var descriptor = new ImplDescriptor(implementor);
      var adheres = true;
      var members = descriptor.getMembers();

      _forEachInstanceProperty(_context2 = _Object$getOwnPropertyNames(this.spec)).call(_context2, function (specKey) {
        var member = descriptor.getMember(specKey);

        if (!member || typeof member !== _this2.spec[specKey]) {
          log.warning(!_includesInstanceProperty(members).call(members, specKey) ? "Missing member: ".concat(specKey) : "Invalid type for ".concat(specKey, ": '").concat(typeof member, "' is not of type '").concat(_this2.spec[specKey], "'"));
          adheres = false;
        }
      });

      return adheres;
    }
  }, {
    key: "hash",
    value: function hash() {
      return this.hashFunc();
    }
  }, {
    key: "toString",
    value: function toString() {
      return "contract {".concat(_Object$keys(this.spec).join(','), "}");
    }
  }], [{
    key: "specify",
    value: function specify(specification) {
      return new Contract(specification);
    }
  }]);

  return Contract;
}();

export { Contract as default };