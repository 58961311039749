import _defineProperty from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import { useEffect, useMemo, useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils.js';
import { countryMessages } from './IntlPhoneNumberField.messages';
export var useInitializeIntlPhoneNumber = function useInitializeIntlPhoneNumber(inputEl, defaultCountry, disabled, placeholder, noDropdown) {
  var translator = useContext(TranslatorContext);
  var localizedCountries = useMemo(function () {
    var localizedCountriesKeys = _Object$keys(countryMessages);

    return _reduceInstanceProperty(localizedCountriesKeys).call(localizedCountriesKeys, function (obj, key) {
      return _objectSpread({}, obj, _defineProperty({}, key, translator(countryMessages[key])));
    }, {});
  }, [countryMessages]);
  useEffect(function () {
    if (!inputEl.current) return undefined;
    var intlPhoneNumberEl = intlTelInput(inputEl.current, {
      initialCountry: defaultCountry,
      preferredCountries: [defaultCountry],
      separateDialCode: !noDropdown,
      autoHideDialCode: !noDropdown,
      allowDropdown: !disabled,
      nationalMode: false,
      formatOnDisplay: true,
      localizedCountries: localizedCountries,
      autoPlaceholder: placeholder ? 'polite' : 'aggressive'
    });

    if (intlPhoneNumberEl) {
      var el = document.getElementsByClassName('iti__selected-dial-code')[0];
      el === null || el === void 0 ? void 0 : el.setAttribute('role', 'textbox');
      el === null || el === void 0 ? void 0 : el.setAttribute('aria-label', 'selected country code');
      el === null || el === void 0 ? void 0 : el.setAttribute('title', 'country code textbox');
    }

    return function () {
      intlPhoneNumberEl.destroy();

      if (inputEl.current) {
        inputEl.current.style.paddingLeft = null;
      }
    };
  }, [defaultCountry, disabled, inputEl, placeholder, noDropdown]);
};
export var useSetNumberOnBlur = function useSetNumberOnBlur(value, intlPhoneNumberInstance, isFocused, noDropdown) {
  useEffect(function () {
    if (!intlPhoneNumberInstance()) {
      return;
    }

    if (!isFocused) {
      if (typeof value === 'object') {
        var _value$countryCode;

        if ((_value$countryCode = value.countryCode) !== null && _value$countryCode !== void 0 && _value$countryCode.code) intlPhoneNumberInstance().setCountry(value.countryCode.code);
        if (!noDropdown && value !== null && value !== void 0 && value.phoneNumber) intlPhoneNumberInstance().setNumber(value.phoneNumber);
      } else if (value) {
        intlPhoneNumberInstance().setNumber(value);
      }
    }
  }, [intlPhoneNumberInstance, isFocused, value]);
};