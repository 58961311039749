import _defineProperty from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { isValidElementType } from 'react-is';
import PropTypes from 'prop-types';
import omit from "lodash/omit";
import { themeConfigShape, deprecated } from '@jutro/prop-types';
import { DropdownSelectField } from '../../inputs/DropdownSelectField/DropdownSelectField';
export var omittedDropdownProps = ['value', 'defaultValue', 'availableValues', 'dataType', 'autoTrim', 'onValueChange', 'model', 'path', 'validationMessages', 'dataPath', 'validator', 'inputType'];
export var themeChooserPropTypes = _objectSpread({}, omit((DropdownSelectField === null || DropdownSelectField === void 0 ? void 0 : DropdownSelectField.propTypes) || {}, omittedDropdownProps), {
  availableThemes: PropTypes.arrayOf(themeConfigShape.isRequired).isRequired,
  onThemeChange: PropTypes.func,
  theme: themeConfigShape,
  defaultTheme: themeConfigShape,
  storybookComponent: deprecated(function (props, propName) {
    if (props[propName] && !isValidElementType(props[propName])) {
      return new Error("Invalid prop 'storybookComponent' supplied to 'ThemeChooser': the prop is not a valid React component");
    }

    return null;
  }, '6.0.0'),
  skipPropagation: PropTypes.bool
});
export var themeChooserDefaultProps = _objectSpread({}, omit((DropdownSelectField === null || DropdownSelectField === void 0 ? void 0 : DropdownSelectField.defaultProps) || {}, omittedDropdownProps), {
  availableThemes: [],
  storybookMode: false,
  skipPropagation: false,
  label: '',
  id: 'ThemeChooser'
});
export var themeChooserStorybookPropNames = ['id', 'availableThemes', 'onThemeChange', 'className', 'label', 'theme', 'skipPropagation'];