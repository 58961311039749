/* eslint-disable max-len */
import _ from 'lodash';
import React, { useEffect, useCallback } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import useBreakpointHandler from '../../../../hooks/useBreakpointHandler';
import { isNotEmptyOrZero } from '../../../../utils/CurrencyAmountUtils';
import { isPropertyLOB } from '../../../../utils/PolicyTypeUtil';
import ContentEstimateComponent from '../ContentEstimateComponent/ContentEstimateComponent';
import useTypelistHandler from '../../../../hooks/useTypelistHandler';
import BankAccountNumberComponent from '../../../BankAccountNumberComponent/BankAccountNumberComponent';
import metadata from './BrokerFNOLRepairEstimates.metadata.json5';
import styles from './BrokerFNOLRepairEstimates.module.scss';
// eslint-disable-next-line no-unused-vars
import messages from '../EstimateComponents.messages';

function BrokerFNOLRepairEstimates(props) {
    const {
        id,
        repairDTO,
        wizardDTO,
        onValidate,
        writeValue,
        showContentEstimateSection,
        canBeSettledViaPV
    } = props;

    const [
        isMobileDevice
    ] = useBreakpointHandler();

    const { getValuesFromTypelistIncludeFilter } = useTypelistHandler();

    const {
        onValidate: onComponentValidate,
        isComponentValid,
        registerComponentValidation
    } = useValidation('FNOLRepairEstimates');

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const componentValidation = useCallback(() => {
        const repairDTOValue = repairDTO.value;
        const isPropertyClaim = isPropertyLOB(wizardDTO.lob.value);
        if (isPropertyClaim && (repairDTOValue.alreadyReceivedEstimate || repairDTOValue.settledViaPVProcedure)) {
            return (repairDTOValue.detailedContentEstimatesPresent === true && !_.isEmpty(repairDTOValue.detailedContentEstimates))
                || (repairDTOValue.detailedContentEstimatesPresent === false && isNotEmptyOrZero(repairDTOValue.estimatedContentRepairCost))
                || isNotEmptyOrZero(repairDTOValue.estimatedBuildingRepairCost);
        }

        if (!isPropertyClaim && repairDTOValue.alreadyReceivedEstimate) {
            return isNotEmptyOrZero(repairDTOValue.estimatedRepairCost);
        }
        return true;
    }, [repairDTO.value, wizardDTO.lob.value]);

    const shouldShowBankAccountDetails = useCallback(() => {
        if (canBeSettledViaPV && repairDTO.value.settledViaPVProcedure === undefined) {
            return false;
        }

        const repairDTOValue = repairDTO.value;
        if (repairDTO.value.settledViaPVProcedure === true) {
            return (isNotEmptyOrZero(repairDTOValue.estimatedBuildingRepairCost)
                || isNotEmptyOrZero(repairDTOValue.estimatedContentRepairCost))
                && repairDTOValue.pvpaymentVatIncluded !== undefined
                && repairDTOValue.pvpaymentPercentage !== undefined;
        }
        return repairDTOValue.damageAlreadyRepaired !== undefined;
    }, [canBeSettledViaPV, repairDTO]);

    const handlePaymentPercentageValueChange = useCallback((value, path) => {
        if (value === 'advance_80') {
            writeValue(false, 'pvpaymentVatIncluded');
        }

        writeValue(value, path);
    }, [writeValue]);

    useEffect(() => {
        registerComponentValidation(componentValidation);
    }, [registerComponentValidation, componentValidation]);

    const mobileOrRegularStyle = useCallback((mobileClassName, className) => {
        return isMobileDevice() ? mobileClassName : className;
    }, [isMobileDevice]);

    const overrideProps = {
        fNOLRepairIsPVBroker: {
            visible: canBeSettledViaPV
        },
        fNOLRepairEstimatesReceived: {
            visible: !canBeSettledViaPV || (canBeSettledViaPV && repairDTO.value.settledViaPVProcedure === false)
        },
        fNOLRepairEstimatesReceivedYes: {
            visible: repairDTO.alreadyReceivedEstimate.value === true || repairDTO.value.settledViaPVProcedure === true
        },
        fNOLRepairEstimatesRepairCost: {
            visible: !isPropertyLOB(wizardDTO.lob.value),
            className: mobileOrRegularStyle('phoneRepairCost', 'repairCost')
        },
        fNOLRepairEstimatesReceivedYesBuildingContent: {
            visible: isPropertyLOB(wizardDTO.lob.value)
        },
        fNOLRepairEstimatesReceivedYesBuildingContentTitles: {
            visible: !canBeSettledViaPV || (canBeSettledViaPV && repairDTO.value.settledViaPVProcedure === false)
        },
        fNOLRepairEstimatesBuildingRepairCost: {
            className: mobileOrRegularStyle('phoneRepairCostWithMargin', 'repairCostWithMargin')
        },
        fNOLRepairEstimatesContentSection: {
            repairDTO: repairDTO,
            wizardDTO: wizardDTO,
            onValidate: onValidate,
            writeValue: writeValue,
            visible: showContentEstimateSection
        },
        pvBrokerDetails: {
            visible: canBeSettledViaPV && repairDTO.value.settledViaPVProcedure === true
        },
        paymentPercentage: {
            availableValues: getValuesFromTypelistIncludeFilter('com.inlb.cc.extsys.edge.anonymousfnol.fnol.dto.RepairDetailsDTO', 'pvpaymentPercentage'),
            className: mobileOrRegularStyle('phoneRepairCostWithMargin', 'repairCostWithMargin'),
            onValueChange: handlePaymentPercentageValueChange
        },
        paymentPVVatIncluded: {
            visible: repairDTO.value.pvpaymentPercentage !== undefined,
            disabled: repairDTO.value.pvpaymentPercentage === 'advance_80'
        },
        fNOLRepairEstimatesFinalEstimate: {
            visible: !canBeSettledViaPV || (canBeSettledViaPV && repairDTO.value.settledViaPVProcedure === false)
        },
        fNOLRepairDamageAlreadyFixed: {
            visible: !canBeSettledViaPV || (canBeSettledViaPV && repairDTO.value.settledViaPVProcedure === false),
            availableValues: getValuesFromTypelistIncludeFilter('com.inlb.cc.extsys.edge.anonymousfnol.fnol.dto.RepairDetailsDTO', 'damageAlreadyRepaired'),
            value: repairDTO.value.damageAlreadyRepaired
        },
        fnolRepairBankAccountNumber: {
            writeValue: writeValue,
            isBrokerContext: true,
            onValidate: onComponentValidate,
            visible: shouldShowBankAccountDetails(),
            accountNumberRequired: repairDTO.value.settledViaPVProcedure === true
        },
        fNOLRepairEstimatesReceivedYesUploadDocument: {
            visible: repairDTO.value.settledViaPVProcedure === true || repairDTO.alreadyReceivedEstimate.value === true
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {},
        resolveComponentMap: {
            ContentEstimateComponent,
            bankAccountComponent: BankAccountNumberComponent
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={repairDTO}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onComponentValidate}
                onValueChange={writeValue}
            />
        </div>
    );
}

export default BrokerFNOLRepairEstimates;
