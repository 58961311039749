import _defineProperty from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import isString from "lodash/isString";
import { TranslatorContext } from '@jutro/locale';
import { intlMessageShape } from '@jutro/prop-types';
import styles from "./InfoLabel.module.css";
import { Icon } from '../Icon/Icon';
import { InlineLabel } from '../InlineLabel/InlineLabel';
import { getFromArray } from '../../helpers';
export var availableInfoLabelTypes = ['success', 'error', 'warning', 'info', 'neutral'];
var defaultInfoLabelType = 'info';

var isContentShort = function isContentShort(content) {
  return isString(content) && content.length === 1;
};

var infoLabelPropTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.string,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  id: PropTypes.string.isRequired,
  message: intlMessageShape,
  type: PropTypes.oneOf(availableInfoLabelTypes)
};
export var InfoLabel = function InfoLabel(_ref) {
  var children = _ref.children,
      className = _ref.className,
      icon = _ref.icon,
      iconPosition = _ref.iconPosition,
      id = _ref.id,
      message = _ref.message,
      type = _ref.type;
  var translator = useContext(TranslatorContext);
  var typeClassName = getFromArray(availableInfoLabelTypes, type, defaultInfoLabelType);
  var content = children || translator(message);
  var hasOnlyIcon = icon && !content;
  var hasOnlyShortContent = !icon && isContentShort(content);
  var classes = cx(styles.infoLabel, styles[typeClassName], _defineProperty({}, styles.simple, hasOnlyIcon || hasOnlyShortContent), className);
  var iconClasses = cx(styles.icon, _defineProperty({}, styles.rightIcon, iconPosition === 'right'));
  var iconComponent = icon && React.createElement(Icon, {
    icon: icon,
    className: iconClasses
  });
  return React.createElement(InlineLabel, {
    id: id,
    icon: iconComponent,
    iconPosition: iconPosition,
    className: classes
  }, React.createElement("span", null, content));
};
InfoLabel.propTypes = infoLabelPropTypes;
InfoLabel.defaultProps = {
  iconPosition: 'left',
  type: defaultInfoLabelType
};
InfoLabel.__docgenInfo = {
  componentName: "InfoLabel",
  packageName: "@jutro/components",
  description: "Displays an \"InfoLabel\" element with child components, such as text or images.",
  displayName: "InfoLabel",
  methods: [],
  actualName: "InfoLabel",
  metadataType: "element",
  props: {
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "The children elements to render inside of the InfoLabel"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    },
    icon: {
      type: {
        name: "string"
      },
      required: false,
      description: "The short-name of the optional Font Awesome icon"
    },
    iconPosition: {
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: false
        }, {
          value: "'right'",
          computed: false
        }]
      },
      required: false,
      description: "Where the icon is placed relative to the text",
      defaultValue: {
        value: "'left'",
        computed: false
      }
    },
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "Component unique identifier"
    },
    message: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Label message that is either a string or an object representing internationalized message ({id: `key`, defaultMessage: `default text`})\nwill be shown when children is not provided"
    },
    type: {
      type: {
        name: "enum",
        value: [{
          value: "'success'",
          computed: false
        }, {
          value: "'error'",
          computed: false
        }, {
          value: "'warning'",
          computed: false
        }, {
          value: "'info'",
          computed: false
        }, {
          value: "'neutral'",
          computed: false
        }]
      },
      required: false,
      description: "Phrase allowing to set custom flavor (success, info, neutral, etc.)",
      defaultValue: {
        value: "'info'",
        computed: false
      }
    }
  }
};