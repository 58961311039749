/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable no-console */
import _ from 'lodash';
import React, { useCallback, useState, useContext, useEffect } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import { CheckboxField } from '@jutro/components';
import { propertyChoices } from './PropertyConfig.json';
import FNOLAddress from '../../Address/Address';
import PropertyDamage from '../PropertyDamage/PropertyDamage';
import styles from './LossDetailsProperty.module.scss';
import metadata from './LossDetailsProperty.metadata.json5';
import messages from './LossDetailsProperty.messages';

function LossDetailsProperty(props) {
    const {
        id,
        onValueChange,
        value: fnolVM,
        onValidate,
        policyHolderRole
    } = props;

    console.log('LossDetailsProperty PH role', policyHolderRole);

    const initializePropertyDamages = useCallback(() => {
        const lossCause = fnolVM.value.frontEndLossCause;
        const damageTypes = fnolVM.value.frontEndDamageTypes;
        const propertyDamages = [];
        console.log('choices', propertyChoices);
        // eslint-disable-next-line array-callback-return
        const propertyAvailableChoices = damageTypes.map((damageType) => {
            const propChoiceFound = propertyChoices.find((propertyChoice) => {
                return propertyChoice.lossCauseCode === lossCause
                        && damageType === propertyChoice.damageTypeCode;
            });

            const propChoiceFoundAsterix = propertyChoices.find((propertyChoice) => {
                return propertyChoice.lossCauseCode === lossCause
                        && propertyChoice.damageTypeCode === '*';
            });

            if (propChoiceFound) {
                return propChoiceFound;
            }
            if (propChoiceFoundAsterix) {
                const propChoiceAsterix = _.cloneDeep(propChoiceFoundAsterix);
                propChoiceAsterix.damageTypeCode = damageType;
                return propChoiceAsterix;
            }

            console.log('property not mapped for:', lossCause, damageType);
        });

        if (propertyAvailableChoices.length === 0 || !propertyAvailableChoices[0]) {
            return;
        }

        propertyAvailableChoices.forEach((propChoice) => {
            propChoice.propertyDamages.forEach((availablePropertyDamage) => {
                if (availablePropertyDamage.holderRoles !== undefined
                    && !availablePropertyDamage.holderRoles.includes(policyHolderRole)) {
                    return;
                }

                const propertyDamage = {
                    damagedProperty: availablePropertyDamage.propertyDamageCode,
                    isSelected: propChoice.propertyDamages.length === 1,
                    haveChanged: propChoice.propertyDamages.length === 1,
                    address: {},
                    damageDetailsList: [],
                    lossCode: propChoice.lossCauseCode,
                    damageCode: propChoice.damageTypeCode
                };
                if (availablePropertyDamage.propertyDamageDetails) {
                    availablePropertyDamage.propertyDamageDetails
                        .forEach((availableDamageDetail) => {
                            const propertyDamageDetail = {
                                damageDetail: availableDamageDetail.propertyDamageDetail,
                                isSelected: availablePropertyDamage.propertyDamageDetails.length === 1,
                                haveChanged: availablePropertyDamage.propertyDamageDetails.length === 1
                            };
                            propertyDamage.damageDetailsList.push(propertyDamageDetail);
                        });
                }
                propertyDamages.push(propertyDamage);
            });
        });

        return propertyDamages;
    }, [fnolVM.value.frontEndDamageTypes, fnolVM.value.frontEndLossCause, policyHolderRole]);

    // init
    const fnolDTO = fnolVM.value;
    if (!fnolDTO.propertyDamage) {
        const initialPropertyDamages = initializePropertyDamages();
        fnolVM.propertyDamage.value = initialPropertyDamages;
    }

    const {
        onValidate: setComponentValidation,
        registerComponentValidation,
        isComponentValid
    } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const handleValueChange = useCallback((value, changedPath) => {
        onValueChange(value, changedPath);
    }, [onValueChange]);

    const propertyOnValueChange = useCallback((value, path, index) => {
        const targetPath = `propertyDamage[${index}].${path}`;
        console.log('ovc: ', value, path, index);
        if (path === 'isSelected' && value === false) {
            fnolDTO.propertyDamage[index]
                .damageDetailsList.forEach((item, detailIndex) => {
                    fnolDTO.propertyDamage[index].damageDetailsList[detailIndex].isSelected = false;
                });
        }
        onValueChange(value, targetPath);
    }, [fnolDTO.propertyDamage, onValueChange]);

    const generateOverridesForPropertyDamage = useCallback(() => {
        const generatedLossAndDmg = fnolVM.propertyDamage.value.map((lossDamage, index) => {
            const newOverride = {
                [`propertyDamage${index}`]: {
                    onValueChange: (value, path) => propertyOnValueChange(value, path, index),
                    showLossAndDamage:
                        (index === 0
                            || !_.isEqual(fnolVM.propertyDamage.value[index].lossCode, fnolVM.propertyDamage.value[index - 1].lossCode)
                            || !_.isEqual(fnolVM.propertyDamage.value[index].damageCode, fnolVM.propertyDamage.value[index - 1].damageCode))
                }
            };
            return newOverride;
        });
        return Object.assign({}, ...generatedLossAndDmg);
    }, [fnolVM.propertyDamage.value, propertyOnValueChange]);

    const overrides = {
        ...generateOverridesForPropertyDamage()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleValueChange,
            onValidate: setComponentValidation
        },
        resolveComponentMap: {
            address: FNOLAddress,
            checkboxField: CheckboxField,
            propertyDamage: PropertyDamage
        }
    };

    const checkIfAnythingSelected = useCallback(() => {
        const selectedCount = fnolVM.propertyDamage.value.filter((el) => el.isSelected).length;
        return selectedCount > 0;
    },
    [fnolVM.propertyDamage]);

    useEffect(() => {
        registerComponentValidation(checkIfAnythingSelected);
    }, [registerComponentValidation, checkIfAnythingSelected]);

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={fnolVM}
                overrideProps={overrides}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={setComponentValidation}
                onValueChange={handleValueChange}
            />
        </div>
    );
}

export default LossDetailsProperty;

