import _defineProperty from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import React, { useRef, useState, useEffect, useLayoutEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { intlMessageShape } from '@jutro/prop-types';
import { uniqueInnerId } from '@jutro/platform';
import { IconButton } from '../IconButton/IconButton';
import styles from "./TabBar.module.css";
export var getTabHeadingId = function getTabHeadingId(id) {
  return uniqueInnerId(id, 'headingElemId');
};
var tabBarPropTypes = {
  onHeadingClick: PropTypes.func,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    heading: PropTypes.oneOfType([PropTypes.node, intlMessageShape]),
    disabled: PropTypes.bool,
    visible: PropTypes.bool,
    active: PropTypes.bool
  })).isRequired,
  className: PropTypes.string,
  ariaControls: PropTypes.string,
  label: PropTypes.string
};
export var TabBar = function TabBar(_ref) {
  var id = _ref.id,
      tabs = _ref.tabs,
      onHeadingClick = _ref.onHeadingClick,
      className = _ref.className,
      label = _ref.label;

  var visibleTabs = _filterInstanceProperty(tabs).call(tabs, function (_ref2) {
    var visible = _ref2.visible;
    return visible !== false;
  });

  var headingButtonRefs = useRef({});
  var nextIconRef = useRef();
  var prevIconRef = useRef();
  var tabBarRef = useRef();
  var tabBarContainerRef = useRef();

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isPrevArrowVisible = _useState2[0],
      setPrevArrowVisibility = _useState2[1];

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      isNextArrowVisible = _useState4[0],
      setNextArrowVisibility = _useState4[1];

  var translator = useContext(TranslatorContext);
  var waitForScrollToFinish = 500;
  var waitForWindowResize = 300;
  var areTabsInMotion = false;
  var selectedTabId = -1;

  var updateNavIconsVisibilityOnResize = function updateNavIconsVisibilityOnResize() {
    if (!_.isNil(tabBarRef)) {
      setPrevArrowVisibility(false);
      setNextArrowVisibility(false);
      var width = 0;
      var isSelectedTabFound = false;

      _.each(tabBarRef.current.children, function (button) {
        if (button.id === selectedTabId) {
          if (width > 0) {
            setPrevArrowVisibility(true);
          }

          tabBarRef.current.scrollTo({
            left: width,
            behavior: 'smooth'
          });
          isSelectedTabFound = true;

          _.delay(updateNavIconsVisibility, waitForScrollToFinish);
        }

        width += button.offsetWidth;
      });

      if (!isSelectedTabFound) {
        tabBarRef.current.scrollTo({
          left: 0,
          behavior: 'smooth'
        });

        _.delay(updateNavIconsVisibility, waitForScrollToFinish);
      }
    }
  };

  var updateNavIconsVisibility = function updateNavIconsVisibility() {
    if (!_.isNil(tabBarRef)) {
      setPrevArrowVisibility(tabBarRef.current.scrollLeft !== 0);

      if (tabBarContainerRef.current.offsetWidth + tabBarRef.current.scrollLeft >= getTabsWidth()) {
        setNextArrowVisibility(false);
      } else {
        setNextArrowVisibility(true);
      }
    }
  };

  var handleKeyDown = function handleKeyDown(event, prevTab, nextTab) {
    var newTab = null;

    switch (event.key) {
      case 'ArrowLeft':
        newTab = headingButtonRefs.current[prevTab];
        break;

      case 'ArrowRight':
        newTab = headingButtonRefs.current[nextTab];
        break;

      default:
        return;
    }

    event.preventDefault();
    newTab.focus();
    newTab.click();
    updateNavIconsVisibility();
  };

  useLayoutEffect(function () {
    updateNavIconsVisibility();
  }, [tabBarRef.current]);
  useEffect(function () {
    var handleWindowResize = function handleWindowResize() {
      _.delay(updateNavIconsVisibilityOnResize, waitForWindowResize);
    };

    window.addEventListener('resize', handleWindowResize);
    return function () {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [updateNavIconsVisibilityOnResize]);

  var moveTabs = function moveTabs(action) {
    if (!areTabsInMotion) {
      areTabsInMotion = true;
      moveTabsContinue(action);
    }
  };

  var showPrevTabs = function showPrevTabs() {
    moveTabs('prev');
  };

  var showNextTabs = function showNextTabs() {
    moveTabs('next');
  };

  var renderHeading = function renderHeading(props) {
    var headingClassName = props.className,
        heading = props.heading,
        headingId = props.id,
        disabled = props.disabled,
        onClick = props.onClick,
        onKeyDown = props.onKeyDown,
        active = props.active,
        collapsible = props.collapsible,
        onFocus = props.onFocus,
        onWheel = props.onWheel,
        ref = props.ref;
    var headingClasses = cx(headingClassName, {
      active: active
    });

    var _getTabHeadingId = getTabHeadingId(headingId),
        headingElemId = _getTabHeadingId.headingElemId;

    if (active) {
      selectedTabId = headingElemId;
    }

    return React.createElement("button", {
      type: "button",
      className: headingClasses,
      onClick: onClick,
      onKeyDown: onKeyDown,
      onWheel: onWheel,
      onFocus: onFocus,
      id: headingElemId,
      "data-tab": headingId,
      key: headingId,
      disabled: disabled,
      role: "tab",
      "aria-controls": headingId,
      "aria-selected": active,
      "data-collapsible": collapsible,
      tabIndex: active ? 0 : -1,
      ref: ref
    }, heading);
  };

  useEffect(function () {
    if (!_.isNil(tabBarContainerRef)) {
      updateNavIconsVisibility();
    }
  }, [visibleTabs.length]);

  var renderBarHeadings = function renderBarHeadings() {
    var headings = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var collapsible = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    return _mapInstanceProperty(headings).call(headings, function (_ref3, i) {
      var headingClassName = _ref3.className,
          headingId = _ref3.id,
          otherProps = _objectWithoutProperties(_ref3, ["className", "id"]);

      return renderHeading(_objectSpread({}, otherProps, {
        id: headingId,
        className: [styles.heading, headingClassName],
        onKeyDown: function onKeyDown(e) {
          var prevHeadingIdx = i === 0 ? headings.length - 1 : i - 1;
          var nextHeadingIdx = i === headings.length - 1 ? 0 : i + 1;
          return handleKeyDown(e, headings[prevHeadingIdx].id, headings[nextHeadingIdx].id);
        },
        onWheel: function onWheel() {
          return updateNavIconsVisibility();
        },
        collapsible: collapsible,
        ref: function ref(_ref4) {
          headingButtonRefs.current[headingId] = _ref4;
        }
      }));
    });
  };

  var getLeftWidthOfNextTab = function getLeftWidthOfNextTab(action) {
    if (!_.isNil(tabBarRef)) {
      var tabWidthCounter = 0;
      var leftWidthOfNextTab = 0;

      if (action === 'next') {
        _.each(tabBarRef.current.children, function (button) {
          tabWidthCounter += button.offsetWidth;

          if (tabWidthCounter >= tabBarRef.current.scrollLeft && tabWidthCounter <= tabBarRef.current.scrollLeft + tabBarContainerRef.current.offsetWidth) {
            leftWidthOfNextTab = tabWidthCounter - button.offsetWidth;
          }
        });
      }

      if (action === 'prev') {
        _.forEachRight(tabBarRef.current.children, function (button) {
          tabWidthCounter += button.offsetWidth;

          if (tabBarContainerRef.current.offsetWidth > tabBarRef.current.scrollLeft) {
            leftWidthOfNextTab = 0;
          }

          if (tabWidthCounter < tabBarContainerRef.current.offsetWidth) {
            leftWidthOfNextTab = tabBarRef.current.scrollLeft - (tabWidthCounter - button.offsetWidth);
          }
        });
      }

      return leftWidthOfNextTab;
    }

    return undefined;
  };

  var moveTabsContinue = function moveTabsContinue(action) {
    var leftWidthOfNextTab = getLeftWidthOfNextTab(action);

    if (leftWidthOfNextTab === 0) {
      setPrevArrowVisibility(false);
    } else {
      setPrevArrowVisibility(true);
    }

    tabBarRef.current.scrollTo({
      left: leftWidthOfNextTab,
      behavior: 'smooth'
    });

    if (tabBarContainerRef.current.offsetWidth + leftWidthOfNextTab > getTabsWidth()) {
      setNextArrowVisibility(false);
    } else {
      setNextArrowVisibility(true);
    }

    areTabsInMotion = false;
  };

  var getTabsWidth = function getTabsWidth() {
    return _reduceInstanceProperty(_).call(_, tabBarRef.current.children, function (width, button) {
      return width + button.offsetWidth;
    }, 0);
  };

  var translateHeader = function translateHeader(heading) {
    return _.isString(heading) || _.isObject(heading) ? translator(heading) : heading;
  };

  var headings = _mapInstanceProperty(visibleTabs).call(visibleTabs, function (_ref5) {
    var heading = _ref5.heading,
        otherProps = _objectWithoutProperties(_ref5, ["heading"]);

    return _objectSpread({
      heading: translateHeader(heading),
      onClick: onHeadingClick
    }, otherProps);
  });

  var tabBarClasses = cx(styles.tabBarContainer, className);

  var renderPrevArrow = function renderPrevArrow() {
    return React.createElement("div", {
      ref: prevIconRef,
      id: "prevTab",
      className: cx(styles.prevIcon, className)
    }, React.createElement(IconButton, {
      disabled: false,
      icon: "mi-chevron-left",
      path: "iconbutton",
      className: cx(styles.prevIconButton, className),
      value: null,
      onClick: showPrevTabs,
      tabIndex: -1
    }));
  };

  var renderNextArrow = function renderNextArrow() {
    return React.createElement("div", {
      ref: nextIconRef,
      id: "nextTab",
      className: cx(styles.nextIcon, className)
    }, React.createElement(IconButton, {
      disabled: false,
      icon: "mi-chevron-right",
      path: "iconbutton",
      className: cx(styles.nextIconButton, className),
      value: null,
      onClick: showNextTabs,
      tabIndex: -1
    }));
  };

  var renderTabBar = function renderTabBar() {
    return React.createElement("div", {
      id: id,
      ref: tabBarRef,
      className: cx(styles.tabBar, className),
      role: "tablist",
      "aria-label": label
    }, renderBarHeadings(headings));
  };

  return React.createElement("div", {
    ref: tabBarContainerRef,
    id: "tabBarContainer",
    className: tabBarClasses
  }, isPrevArrowVisible && renderPrevArrow(), renderTabBar(), isNextArrowVisible && renderNextArrow());
};
TabBar.propTypes = tabBarPropTypes;
TabBar.__docgenInfo = {
  componentName: "TabBar",
  packageName: "@jutro/components",
  description: "TabBar",
  displayName: "TabBar",
  methods: [],
  actualName: "TabBar",
  props: {
    onHeadingClick: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback for clicking on bar items"
    },
    tabs: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            heading: {
              name: "union",
              value: [{
                name: "node"
              }, {
                name: "union",
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: false
                    },
                    defaultMessage: {
                      name: "string",
                      required: false
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: false
                    }
                  }
                }]
              }],
              required: false
            },
            disabled: {
              name: "bool",
              required: false
            },
            visible: {
              name: "bool",
              required: false
            },
            active: {
              name: "bool",
              required: false
            }
          }
        }
      },
      required: true,
      description: "A set of tabs rendering metadata"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Optional class name to apply to this component"
    },
    ariaControls: {
      type: {
        name: "string"
      },
      required: false,
      description: "Identifier of the region displaying selected tab's content"
    },
    label: {
      type: {
        name: "string"
      },
      required: false,
      description: "Optional label describing the purpose of the tablist"
    }
  }
};