import _defineProperty from "C:\\Building-application\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _Array$isArray from "@babel/runtime-corejs3/core-js-stable/array/is-array";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _someInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/some";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _startsWithInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/starts-with";
import _forEachInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/for-each";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import Ajv from 'ajv';
import merge from "lodash/merge";
import get from "lodash/get";
import replace from "lodash/replace";

var regexGlobPattern = function regexGlobPattern() {
  return /\[([0-9]+)\]/g;
};

var resolveCustomMessagesPath = function resolveCustomMessagesPath(errorPath) {
  return replace(errorPath, regexGlobPattern(), '.*');
};

var JsonFormValidator = function () {
  function JsonFormValidator() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        dataSchemaExtension = _ref.dataSchemaExtension,
        options = _ref.options,
        ValidationService = _ref.ValidationService;

    _classCallCheck(this, JsonFormValidator);

    this.validationService = ValidationService ? new ValidationService(_objectSpread({}, options, {
      allErrors: true
    })) : new Ajv(_objectSpread({}, options, {
      allErrors: true,
      extendRefs: true
    }));
    this.validator = null;
    this.customMessages = {};
    this.definitions = {};
    this.dataSchemaExtension = dataSchemaExtension;
  }

  _createClass(JsonFormValidator, [{
    key: "validate",
    value: function validate(schema, data) {
      if (!this.validator) {
        this.validator = this.validationService.compile(schema);
      }

      return this.validator(data);
    }
  }, {
    key: "errorMessagesByPath",
    value: function errorMessagesByPath() {
      var _this = this;

      if (!this.validator || !this.validator.errors) {
        return undefined;
      }

      var validationProps = {};
      var errors = this.validator.errors;

      _forEachInstanceProperty(errors).call(errors, function (error) {
        var path = error.dataPath;

        if (error.keyword === 'required') {
          path = "".concat(path, ".").concat(error.params.missingProperty);
        } else if (error.keyword === 'dependencies') {
          path = "".concat(path, ".").concat(error.params.deps);
        } else if (error.keyword === 'forbidden') {
          path = "".concat(path, ".").concat(error.params.forbiddenProperty);
        }

        if (_startsWithInstanceProperty(path).call(path, '.')) {
          path = path.substr(1);
        }

        var message = _this.getErrorMessage(error, path);

        validationProps[path] = [message];
      });

      return validationProps;
    }
  }, {
    key: "errorText",
    value: function errorText() {
      if (!this.validator || !this.validator.errors) {
        return undefined;
      }

      return this.validationService.errorsText(this.validator.errors);
    }
  }, {
    key: "dataProps",
    value: function dataProps(schema, data, resolveDataType) {
      var overrideDataProps = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      this.customMessages = {};
      this.definitions = schema.definitions;
      this.components = schema.components;
      var dataProps = {};
      this.processDataProps(schema, data, dataProps, resolveDataType || this.dataSchemaExtension);
      var mergedDataProps = merge(dataProps, overrideDataProps);
      return mergedDataProps;
    }
  }, {
    key: "findReference",
    value: function findReference(ref) {
      var refPath = ref.replace(/\//g, '.').replace(/~1/g, '/');

      if (_startsWithInstanceProperty(refPath).call(refPath, '#.components.')) {
        return get(this.components, refPath.substr('#.components.'.length));
      }

      return get(this.definitions, refPath.substr('#.definitions.'.length));
    }
  }, {
    key: "resolveReference",
    value: function resolveReference(schema) {
      if (schema.$ref) {
        var $ref = schema.$ref,
            schemaProps = _objectWithoutProperties(schema, ["$ref"]);

        var refProps = this.findReference($ref);

        if (refProps) {
          return _objectSpread({}, refProps, {}, schemaProps);
        }
      }

      return schema;
    }
  }, {
    key: "getErrorMessage",
    value: function getErrorMessage(error, path) {
      var message = error.message;
      var resolvedPath = resolveCustomMessagesPath(path);
      var customMessages = this.customMessages[resolvedPath];

      if (customMessages && customMessages[error.keyword]) {
        message = customMessages[error.keyword];

        if (error.params && _includesInstanceProperty(message).call(message, '{')) {
          message = substituteMessageParams(message, error.params);
        }
      }

      return message;
    }
  }, {
    key: "getDataType",
    value: function getDataType(schemaItem, schemaItemProps, resolveDataType) {
      var type = schemaItem.type,
          format = schemaItem.format;
      var datatype = type;
      var otherProps = {};

      if (resolveDataType) {
        var answer = resolveDataType(schemaItem, schemaItemProps);

        if (answer) {
          return _objectSpread({
            datatype: datatype,
            processChildProperties: false
          }, answer);
        }
      }

      if (type === 'object' || type === 'array') {
        return {
          datatype: datatype,
          otherProps: otherProps
        };
      }

      if (type === 'string' && format === 'date') {
        datatype = 'date';
        otherProps.dataType = 'string';
      }

      if (type === 'string' && format === 'date-time') {
        datatype = 'date';
        otherProps.dataType = 'date-time';
      }

      if (type === 'string' && schemaItem.enum) {
        datatype = 'select';
        var enumValues = schemaItem.enum,
            enumNames = schemaItem.enumNames;

        var availableValues = _mapInstanceProperty(enumValues).call(enumValues, function (value, i) {
          var label = enumNames && enumNames[i] || value.toString();
          return {
            code: value,
            name: label
          };
        });

        otherProps.availableValues = availableValues;
        otherProps.dataType = 'string';
      }

      return {
        datatype: datatype,
        otherProps: otherProps
      };
    }
  }, {
    key: "getIsRequired",
    value: function getIsRequired(key, required, dependencies, basePath, data) {
      if (required && _includesInstanceProperty(required).call(required, key)) {
        return true;
      }

      if (dependencies) {
        var _context;

        var dependencyKeys = _Object$keys(dependencies);

        return _someInstanceProperty(_context = _filterInstanceProperty(dependencyKeys).call(dependencyKeys, function (source) {
          var _context2;

          return _Array$isArray(dependencies[source]) && _includesInstanceProperty(_context2 = dependencies[source]).call(_context2, key);
        })).call(_context, function (source) {
          var sourcePath = basePath ? "".concat(basePath, ".").concat(source) : source;
          var sourceValue = get(data, sourcePath);
          return sourceValue !== undefined;
        });
      }

      return false;
    }
  }, {
    key: "processDataProps",
    value: function processDataProps(schema, data, dataProps, resolveDataType, basePath, hiddenByDependency) {
      var _this2 = this;

      if (!schema.properties && !schema.$ref) {
        return;
      }

      var schemaRoot = this.resolveReference(schema);

      if (!schemaRoot.properties) {
        return;
      }

      var keys = _Object$keys(schemaRoot.properties);

      var required = schemaRoot.required,
          dependencies = schemaRoot.dependencies,
          properties = schemaRoot.properties,
          definitions = schemaRoot.definitions,
          schemaParentItem = _objectWithoutProperties(schemaRoot, ["required", "dependencies", "properties", "definitions"]);

      _forEachInstanceProperty(keys).call(keys, function (key) {
        var path = basePath ? "".concat(basePath, ".").concat(key) : key;

        var schemaItem = _this2.resolveReference(schemaRoot.properties[key]);

        if (schemaItem.oneOf) {
          var _context3;

          _forEachInstanceProperty(_context3 = schemaItem.oneOf).call(_context3, function (oneOfItem) {
            _this2.processDataProps(oneOfItem, data, dataProps, resolveDataType, path, hiddenByDependency);
          });

          return;
        }

        var title = schemaItem.title,
            maxLength = schemaItem.maxLength,
            messages = schemaItem.messages,
            _schemaItem$nullable = schemaItem.nullable,
            nullable = _schemaItem$nullable === void 0 ? schemaItem['x-gw-nullable'] : _schemaItem$nullable,
            readOnly = schemaItem.readOnly;
        var dataPath = schemaRoot.properties[key].$ref;

        var _this$getDataType = _this2.getDataType(schemaItem, {
          schemaParentItem: schemaParentItem,
          schemaItemKey: key,
          data: data || {},
          dataPath: dataPath || '',
          path: path
        }, resolveDataType),
            datatype = _this$getDataType.datatype,
            component = _this$getDataType.component,
            _this$getDataType$oth = _this$getDataType.otherProps,
            otherProps = _this$getDataType$oth === void 0 ? {} : _this$getDataType$oth,
            _this$getDataType$pro = _this$getDataType.processChildProperties,
            processChildProperties = _this$getDataType$pro === void 0 ? true : _this$getDataType$pro;

        var isRequired = _this2.getIsRequired(key, required, dependencies, basePath, data);

        if (readOnly) {
          otherProps.readOnly = readOnly;
        }

        if (maxLength) {
          otherProps.maxLength = maxLength;
        }

        if (nullable) {
          otherProps.nullable = nullable;
        }

        if (isRequired && !hiddenByDependency) {
          otherProps.schemaRequired = isRequired;
        }

        if (hiddenByDependency) {
          otherProps.visible = false;
        }

        if (messages) {
          _this2.customMessages[path] = messages;
        }

        dataProps[path] = {
          componentProps: _objectSpread({}, title ? {
            label: title
          } : {}, {}, otherProps)
        };

        if (datatype) {
          dataProps[path].datatype = datatype;
        }

        if (component) {
          dataProps[path].component = component;
        }

        if (processChildProperties) {
          var schemaItemType = schemaItem.type;

          if (schemaItemType === 'object') {
            _this2.processDataProps(schemaItem, data, dataProps, resolveDataType, path, hiddenByDependency);

            return;
          }

          if (schemaItemType === 'array') {
            _this2.processDataProps(schemaItem.items, data, dataProps, resolveDataType, "".concat(path, ".*"), hiddenByDependency);

            return;
          }
        }

        if (dependencies && dependencies[key] && !_Array$isArray(dependencies[key])) {
          var keyValue = get(data, path);

          _this2.processDataProps(dependencies[key], data, dataProps, resolveDataType, basePath, hiddenByDependency || keyValue === undefined);
        }
      });
    }
  }]);

  return JsonFormValidator;
}();

export { JsonFormValidator as default };
export function substituteMessageParams(message, values) {
  var newMessage = message;

  if (message && values) {
    var keys = _Object$keys(values);

    _forEachInstanceProperty(keys).call(keys, function (key) {
      newMessage = newMessage.replace(new RegExp("{".concat(key, "}"), 'g'), values[key]);
    });
  }

  return newMessage;
}